// 检定报警器流程页面
import React, { Component } from 'react'
import {Layout, Button, Tabs, Input, Col, Row, message, Modal, Steps, Form, Select, Spin, Popconfirm} from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons';
import { NavLink } from "react-router-dom"
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import FormsTwo from '../../../components/common/forms/FormsTwo'
import moment from 'moment'
import {funTraceInfo} from '../../../services/common'
import './verificationRegistration.less'
import {TYPE_NAME} from '../../../components/Const'
import {
	getNewTemporaryGasCylinderList1,
	getNewTemporaryGasCylinderList2,
	getCylinderList,
	gasAlarmVerifierRecord,
	updateCylinder,
	deleteTemporaryGasCylinder,
	AlarmRecordInGasId,
	checkVerifierRecord,
	getRepeatability,
	getZeroWander,
	getMileageDrift,
	getIndicationError,
	getTemporaryGasCylinderList1_1,
	getTemporaryGasCylinderList2_1
} from '../../../services/verifvication'
import {PlusSquareOutlined,ExportOutlined} from '@ant-design/icons';

import $, { data } from 'jquery';

const { TextArea } = Input;
const { Step } = Steps;
const steps = [
	{
	  title: 'First',
	  content: 'First-content',
	},
	{
	  title: 'Second',
	  content: 'Second-content',
	},
	{
	  title: 'Last',
	  content: 'Last-content',
	},
  ];


export default class AddPoint extends Component {

    state = {
		verificationStatus:false,
		reason:this.props.location?.state?.reason || '无内容',
		reasonSwitch:'none',
				// 表格参数
				tableParamenter: {
					isNotInitData:true,// 初始化不查找
					paginationWidth:true,
					manageName: "数据项管理",
					// 表格行是否可选
					// rowSelection: true,
					//下拉框长度
					selectWidth: '6.5vw',
					// 表头：变量和变量解释
					header: {
						deviceName: '名称',
						model: '规格',
						certificate: '证书编号',
						uncertainValue: '不确定度/准确度等级(最大允许误差)',
						detonationPoint:'爆点',
						expirationTime: '有效期',
						remark: '短名',
						edit: '操作',
					},
					// 弹出框
					headers: {
						deviceName: '名称',
						model: '规格',
						certificate: '证书编号',
						uncertainValue: '不确定度/准确度等级(最大允许误差)',
						detonationPoint:'爆点',
						expirationTime: '有效期',
						remark: '短名',
					},
					// 是否绑定左侧区域
					bindArea: false,
					params: {
						// type: 1,	// 委托单
						userId:JSON.parse(sessionStorage.getItem("user")).id,
						// page:1,
						// size:10,
						// gasId:this.props.location?.state.id,
					}
				},
				// 表格参数
				tableTwoParamenter: {
					isNotInitData:true,// 初始化不查找
					paginationWidth:true,
					manageName: "数据项管理",
					// 表格行是否可选
					// rowSelection: true,
					//下拉框长度
					selectWidth: '6.5vw',
					// 表头：变量和变量解释
					// 表头：变量和变量解释
					header: {
						deviceName: '名称',
						model: '规格',
						measuringRange: '测量范围',
						detonationPoint:'爆点',
						// certificate: '证书编号',
						uncertainValue: '不确定度/准确度等级(最大允许误差)',
						expirationTime: '有效期',
						remark: '短名',
						edit: '操作',
					},
					// 弹出框
					headers: {
						deviceName: '名称',
						model: '规格',
						measuringRange: '测量范围',
						detonationPoint:'爆点',
						// certificate: '证书编号',
						uncertainValue: '不确定度/准确度等级(最大允许误差)',
						expirationTime: '有效期',
						remark: '短名',
					},
					// 是否绑定左侧区域
					bindArea: false,
					params: {
						// type: 2,	// 委托单
						userId:JSON.parse(sessionStorage.getItem("user")).id,
						// page:1,
						// size:10,
						// gasId:this.props.location?.state.id,
					}
				},
				// 添加表格参数
			tableListParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            // rowSelection: true,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				// deviceName: '设备名称',
				model: '型号',
				measuringRange: '测量范围',
				detonationPoint:'爆点',
				uncertainValue: '误差值',
				startNumber: '制造厂及出厂编号',
				// timings: '检定周期或复校间隔',
				nearFuture: '末次检定或校准日期',
				certificate: '检定或校准机构及证书号',
				remark: '备注',
				edit: '操作',

			},
			// 弹出框
			headers: {
				deviceName: '设备名称',
				model: '型号',
				measuringRange: '测量范围',
				detonationPoint:'爆点',
				uncertainValue: '误差值',
				// startNumber: '制造厂及出厂编号',
				// timings: '检定周期或复校间隔',
				// nearFuture: '末次检定或校准日期',
				// certificate: '检定或校准机构及证书号',
				remark: '备注'
			},
			params: {
				type:'配置管理',
				page:1,
				size:10,
			},
			// 是否绑定左侧区域
			bindArea: false
		},
				// 总条数
				totalCount: 0,

				params: {
						page:1,
						size:10,
						  username: null,
					  // organizationId :this.state.treeId
							// areaId: localStorage.getItem('menus')
				},
        // 表单参数
		formParamenter: {
			modal: true,
			number: 4,
            labelCol: 8,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					addonAfter:'℃',
					variableName: 'temperature',
					str: '环境温度',
					numLength:1,
					rules: TYPE_NAME.TEMPERATURE,
				},
				{
					addonAfter:'%',
					variableName: 'humidity',
					str: '相对湿度',
					numLength:1,
					rules: TYPE_NAME.TEMPERATURE,
					// rules: TYPE_NAME.ID_NUMBER_STR,
				},
				// {
				// 	variableName: 'dataTime',
				// 	time: true,
				// 	str: '时间',
				// 	rules: TYPE_NAME.DATE_PICKER,
				// },
			]
		},
		//仪器基本信息表单参数
		formTwoParamenter: {
			modal: true,
			number: 4,
            labelCol: 8,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{variableName: 'inspectionUnit', str: '送检单位', rules: TYPE_NAME.INPUT,},
				{variableName: 'equipmentName', str: '仪器名称', rules: TYPE_NAME.INPUT,},
				{variableName: 'manufacturers', str: '制造厂家', rules: TYPE_NAME.INPUT,},
				{variableName: 'model', str: '型号规格', rules: TYPE_NAME.LABLE_NOT_NULL_20,},
				{variableName: 'mileage', str: '量程', rules: TYPE_NAME.LABLE_NOT_NULL_20,},
				{variableName: 'startNumber', str: '出厂编号', rules: TYPE_NAME.LABLE_NOT_NULL_50,},
				{variableName: 'method',
					str: '采样方式', rules: TYPE_NAME.REQ_SELECT,
					data: [
						{key: 1, value: '扩散式'},
						{key: 2, value: '吸入式'}
						]
				},

				{
					variableName: 'samplingEquipment',
					str: '检定设备',
					rules: TYPE_NAME.REQ_SELECT,
					data: [
						{key: 1, value: '固定式'},
						{key: 2, value: '便携式'}
						]
				},
				{
					variableName: 'verificationBasis',
					str: '检定依据',
					rules: TYPE_NAME.REQ_SELECT,
					data: [
						{
							key: 'JJG693-2011可燃气体检测报警器',
							value: 'JJG693-2011可燃气体检测报警器'
						}
					]
				},
				{variableName: 'verifierDate', str: '检定日期', rules: TYPE_NAME.DATE_PICKER1,},
				{
					variableName: 'type',
					str: '检定类型',
					rules: TYPE_NAME.REQ_SELECT,
					onChange: e => this.onChangeSelect(e),
					data: [
						{key: 1, value: '首次检定'},
						{key: 2, value: '后续检定'},
						{key: 3, value: '使用中检查'}
					]

				},


			]
		},
		//检定结果表单参数
		formResParamenter1: {
					modal: true,
					number: 4,
					labelCol: 8,
					layout: "horizontal",
					// itemSize: 'large',
					parameter: [
						{
							variableName: 'appearanceAndStructure',
							str: '外观和结构',
							rules: TYPE_NAME.REQ_SELECT,
							data: [
								{
									key: 0,
									value: '合格'
								},
								{
									key: 1,
									value: '不合格'
								}
							]
						},
						{
							variableName: 'signsAndMarks',
							str: '标志与标识',
							rules: TYPE_NAME.REQ_SELECT,
							data: [
								{
									key: 0,
									value: '合格'
								},
								{
									key: 1,
									value: '不合格'
								}
							]
						},
						{
							variableName: 'powerOnTest',
							str: '通电检测',
							rules: TYPE_NAME.REQ_SELECT,
							data: [
								{
									key: 0,
									value: '合格'
								},
								{
									key: 1,
									value: '不合格'
								}
							]
						},
						{
							variableName: 'insulationResistance',
							str: '绝缘电阻',
							addonAfter:'MΩ',
							rules: TYPE_NAME.NUMBER,
							onChange: e => {
								this.forms3.setFieldsValue('insulationResistance',parseFloat(e).toFixed(0) )
							},
						},


					]
		},
				//报警功能
			formResParamenter2: {
				modal: true,
				number: 3,
				labelCol: 8,
				layout: "horizontal",
				// itemSize: 'large',
				parameter: [

					{
						variableName: 'indicationOne',
						str: '实测报警值一(%LEL)',
						numLength:1,
						min:0,
						max:100,
						rules: TYPE_NAME.PERCENTAGE,
						onChange: e => this.formResParamenter2(),
					},
					{
						variableName: 'indicationTwo',
						str: '实测报警值二(%LEL)',
						numLength:1,
						min:0,
						max:100,
						rules: TYPE_NAME.PERCENTAGE,
						onChange: e => this.formResParamenter2(),
					},
					{
						variableName: 'indicationThree',
						str: '实测报警值三(%LEL)',
						numLength:1,
						min:0,
						max:100,
						rules: TYPE_NAME.PERCENTAGE,
						onChange: e => this.formResParamenter2(),
					},

				]
		},
		formResParamenter22: {
			modal: true,
			number: 3,
			labelCol: 8,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					disabled: true,
					variableName: 'operateValue',
					str: '报警动作值(%LEL)',
					numLength:1,
					min:0,
					max:100,
					rules: TYPE_NAME.PERCENTAGE,

				},
				{
					variableName: 'warningFunction',
					str: '报警功能',
					rules: TYPE_NAME.REQ_SELECT,
					data: [
						{
							key: "0",
							value: '合格'
						},
						{
							key: "1",
							value: '不合格'
						}
					]
				},

			]
		},

			//示值误差
			formSzParamenter1: {
					modal: true,
					number: 3,
					labelCol: 8,
					layout: "horizontal",
					// itemSize: 'large',
					parameter: [
						{
							disabled: true,
							addonAfter:'%',
							variableName: 'normalConcentration',
							str: '标气浓度一',
							numLength:1,
							min:0,
							max:100,
							rules: TYPE_NAME.PERCENTAGE,
							onChange: e => this.formResParamenter7(),
						},

					]
			},
			formSzParamenter11: {
				modal: true,
				number: 3,
				labelCol: 8,
				layout: "horizontal",
				// itemSize: 'large',
				parameter: [
					{
						variableName: 'indicationOne',
						str: '仪器示值一(%LEL)',
						numLength:1,
						min:0,
						max:100,
						rules: TYPE_NAME.PERCENTAGE,
						onChange: e => this.formResParamenter7(),
					},
					{
						variableName: 'indicationTwo',
						str: '仪器示值二(%LEL)',
						numLength:1,
						min:0,
						max:100,
						rules: TYPE_NAME.PERCENTAGE,
						onChange: e => this.formResParamenter7(),
					},
					{
						variableName: 'indicationThree',
						str: '仪器示值三(%LEL)',
						numLength:1,
						min:0,
						max:100,
						rules: TYPE_NAME.PERCENTAGE,
						onChange: e => this.formResParamenter7(),
					},

				]
		},
		formSzParamenter111: {
			modal: true,
			number: 3,
			labelCol: 8,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'averageValue',
					str: '平均值一(%LEL)',
					disabled: true,
					// rules: TYPE_NAME.NUMBER,
					numLength:1,
					rules: TYPE_NAME.PERCENTAGE,
				},
				{
					variableName: 'indicationError',
					str: '示值误差一',
					disabled: true,
					rules: TYPE_NAME.NUMBER,

				},

			]
	},
			//示值误差
			formSzParamenter2: {
								modal: true,
								number: 3,
								labelCol: 8,
								layout: "horizontal",
								// itemSize: 'large',
								parameter: [
									{
										disabled: true,
										addonAfter:'%',
										variableName: 'normalConcentration',
										str: '标气浓度二',
										min:0,
										max:100,
										numLength:1,
										rules: TYPE_NAME.PERCENTAGE,
										onChange: e => this.formResParamenter8(),
									},

								]
						},
						formSzParamenter22: {
							modal: true,
							number: 3,
							labelCol: 8,
							layout: "horizontal",
							// itemSize: 'large',
							parameter: [
								{
									variableName: 'indicationOne',
									str: '仪器示值一(%LEL)',
									numLength:1,
									min:0,
									max:100,
									rules: TYPE_NAME.PERCENTAGE,
									onChange: e => this.formResParamenter8(),
								},
								{
									variableName: 'indicationTwo',
									str: '仪器示值二(%LEL)',
									numLength:1,
									min:0,
									max:100,
									rules: TYPE_NAME.PERCENTAGE,
									onChange: e => this.formResParamenter8(),
								},
								{
									variableName: 'indicationThree',
									str: '仪器示值三(%LEL)',
									numLength:1,
									min:0,
									max:100,
									rules: TYPE_NAME.PERCENTAGE,
									onChange: e => this.formResParamenter8(),
								},
							]
					},
					formSzParamenter222: {
						modal: true,
						number: 3,
						labelCol: 8,
						layout: "horizontal",
						// itemSize: 'large',
						parameter: [
							{
								variableName: 'averageValue',
								str: '平均值二(%LEL)',
								disabled: true,
								// rules: TYPE_NAME.NUMBER,
								numLength:1,
								rules: TYPE_NAME.PERCENTAGE,
							},
							{
								variableName: 'indicationError',
								str: '示值误差二',
								disabled: true,
								rules: TYPE_NAME.NUMBER,

							},

						]
				},
				//示值误差
				formSzParamenter3: {
								modal: true,
								number: 3,
								labelCol: 8,
								layout: "horizontal",
								// itemSize: 'large',
								parameter: [
									{
										disabled: true,
										addonAfter:'%',
										variableName: 'normalConcentration',
										str: '标气浓度三',
										numLength:1,
										min:0,
										max:100,
										rules: TYPE_NAME.PERCENTAGE,
										onChange: e => this.formResParamenter9(),
									},
								]
				},
				formSzParamenter33: {
					modal: true,
					number: 3,
					labelCol: 8,
					layout: "horizontal",
					// itemSize: 'large',
					parameter: [
						{
							variableName: 'indicationOne',
							str: '仪器示值一(%LEL)',
							numLength:1,
							min:0,
							max:100,
							rules: TYPE_NAME.PERCENTAGE,
							onChange: e => this.formResParamenter9(),
						},
						{
							variableName: 'indicationTwo',
							str: '仪器示值二(%LEL)',
							numLength:1,
							min:0,
							max:100,
							rules: TYPE_NAME.PERCENTAGE,
							onChange: e => this.formResParamenter9(),
						},
						{
							variableName: 'indicationThree',
							str: '仪器示值三(%LEL)',
							numLength:1,
							min:0,
							max:100,
							rules: TYPE_NAME.PERCENTAGE,
							onChange: e => this.formResParamenter9(),
						},
					]
				},
				formSzParamenter333: {
					modal: true,
					number: 3,
					labelCol: 8,
					layout: "horizontal",
					// itemSize: 'large',
					parameter: [
						{
							variableName: 'averageValue',
							str: '平均值三(%LEL)',
							disabled: true,
							// rules: TYPE_NAME.NUMBER,
							numLength:1,
							rules: TYPE_NAME.PERCENTAGE,
						},
						{
							variableName: 'indicationError',
							str: '示值误差三',
							disabled: true,
							rules: TYPE_NAME.NUMBER,

						},

					]
				},
				//响应时间
				formResParamenter3: {
					modal: true,
					number: 3,
					labelCol: 8,
					layout: "horizontal",
					// itemSize: 'large',
					parameter: [
						{
							variableName: 'indicationOne',
							str: '测试结果一',
							numLength:2,
							rules: TYPE_NAME.PERCENTAGE,
							onChange:e=>this.formResParamenter3()
						},
						{
							variableName: 'indicationTwo',
							str: '测试结果二',
							numLength:2,
							rules: TYPE_NAME.PERCENTAGE,
							onChange: e => this.formResParamenter3(),
						},
						{
							variableName: 'indicationThree',
							str: '测试结果三',
							numLength:2,
							rules: TYPE_NAME.PERCENTAGE,
							onChange: e => this.formResParamenter3(),
						},
						{
							variableName: 'average',
							str: '平均值(%LEL)',
							disabled: true,
							rules: TYPE_NAME.NUMBER,
							// numLength:2,
							// rules: TYPE_NAME.PERCENTAGE,
						},

					]
			},
				//重复性(标准值40%LEL)
				formResParamenter4: {
					modal: true,
					number: 3,
					labelCol: 8,
					layout: "horizontal",
					// itemSize: 'large',
					parameter: [
						{
							variableName: 'indicationOne',
							str: '示值1～6一(%LEL)',
							numLength:1,
							min:1,
							max:100,
							rules: TYPE_NAME.PERCENTAGE,
							onChange: e => this.formResParamenter4(),
						},
						{
							variableName: 'indicationTwo',
							str: '示值1～6二(%LEL)',
							numLength:1,
							min:1,
							max:100,
							rules: TYPE_NAME.PERCENTAGE,
							onChange: e => this.formResParamenter4(),
						},
						{
							variableName: 'indicationThree',
							str: '示值1～6三(%LEL)',
							numLength:1,
							min:1,
							max:100,
							rules: TYPE_NAME.PERCENTAGE,
							onChange: e => this.formResParamenter4(),
						},
						{
							variableName: 'indicationFour',
							str: '示值1～6四(%LEL)',
							numLength:1,
							min:1,
							max:100,
							rules: TYPE_NAME.PERCENTAGE,
							onChange: e => this.formResParamenter4(),
						},
						{
							variableName: 'indicationFive',
							str: '示值1～6五(%LEL)',
							numLength:1,
							min:1,
							max:100,
							rules: TYPE_NAME.PERCENTAGE,
							onChange: e => this.formResParamenter4(),
						},
						{
							variableName: 'indicationSix',
							str: '示值1～6六(%LEL)',
							numLength:1,
							min:1,
							max:100,
							rules: TYPE_NAME.PERCENTAGE,
							onChange: e => this.formResParamenter4(),
						},
						{
							variableName: 'averageValue',
							str: '平均值(%LEL)',
							disabled: true,
							rules: TYPE_NAME.NUMBER,

						},
						{
							variableName: 'repeatability',
							str: '重复性',
							disabled: true,
							rules: TYPE_NAME.NUMBER,

						},

					]
			},
				//零点漂移
				formResParamenter5: {
					modal: true,
					number: 8,
					labelCol: 10,
					layout: "horizontal",
					// itemSize: 'large',
					parameter: [
						{
							variableName: 'indicationOne',
							str: '0',
							numLength:1,
							// min:1,
							max:100,
							rules: TYPE_NAME.PERCENTAGE,
							onChange: e => this.formResParamenter5(),
						},
						{
							variableName: 'indicationTwo',
							str: '1h',
							numLength:1,
							// min:1,
							max:100,
							rules: TYPE_NAME.PERCENTAGE,
							onChange: e => this.formResParamenter5(),
						},
						{
							variableName: 'indicationThree',
							str: '2h',
							numLength:1,
							// min:1,
							max:100,
							rules: TYPE_NAME.PERCENTAGE,
							onChange: e => this.formResParamenter5(),
						},
						{
							variableName: 'indicationFour',
							str: '3h',
							numLength:1,
							// min:1,
							max:100,
							rules: TYPE_NAME.PERCENTAGE,
							onChange: e => this.formResParamenter5(),
						},
						{
							variableName: 'indicationFive',
							str: '4h',
							numLength:1,
							// min:1,
							max:100,
							rules: TYPE_NAME.PERCENTAGE,
							onChange: e => this.formResParamenter5(),
						},
						{
							variableName: 'indicationSix',
							str: '5h',
							numLength:1,
							// min:1,
							max:100,
							rules: TYPE_NAME.PERCENTAGE,
							onChange: e => this.formResParamenter5(),
						},
						{
							variableName: 'indicationSeven',
							str: '6h',
							numLength:1,
							// min:1,
							max:100,
							rules: TYPE_NAME.PERCENTAGE,
							onChange: e => this.formResParamenter5(),
						},
						{
							variableName: 'zeroIndication',
							str: '零点示值',
							disabled: true,
							rules: TYPE_NAME.NUMBER,

						},
					]
			},
			//量程漂移
							formResParamenter6: {
								modal: true,
								number: 8,
								labelCol: 10,
								layout: "horizontal",
								// itemSize: 'large',
								parameter: [
									{
										variableName: 'indicationOne',
										str: '0',
										numLength:1,
										// min:1,
										max:100,
										rules: TYPE_NAME.PERCENTAGE,
										onChange: e => this.formResParamenter6(),
									},
									{
										variableName: 'indicationTwo',
										str: '1h',
										numLength:1,
										// min:1,
										max:100,
										rules: TYPE_NAME.PERCENTAGE,
										onChange: e => this.formResParamenter6(),
									},
									{
										variableName: 'indicationThree',
										str: '2h',
										numLength:1,
										// min:1,
										max:100,
										rules: TYPE_NAME.PERCENTAGE,
										onChange: e => this.formResParamenter6(),
									},
									{
										variableName: 'indicationFour',
										str: '3h',
										numLength:1,
										// min:1,
										max:100,
										rules: TYPE_NAME.PERCENTAGE,
										onChange: e => this.formResParamenter6(),
									},
									{
										variableName: 'indicationFive',
										str: '4h',
										numLength:1,
										// min:1,
										max:100,
										rules: TYPE_NAME.PERCENTAGE,
										onChange: e => this.formResParamenter6(),
									},
									{
										variableName: 'indicationSix',
										str: '5h',
										numLength:1,
										// min:1,
										max:100,
										rules: TYPE_NAME.PERCENTAGE,
										onChange: e => this.formResParamenter6(),
									},
									{
										variableName: 'indicationSeven',
										str: '6h',
										numLength:1,
										// min:1,
										max:100,
										rules: TYPE_NAME.PERCENTAGE,
										onChange: e => this.formResParamenter6(),
									},
									{
										variableName: 'rangeIndication',
										str: '量程示值',
										disabled: true,
										rules: TYPE_NAME.NUMBER,

									},

								]
						},
		stepStatus:0,
		stepBox1:'block',
		stepBox2:'none',
		btnBox:'none',
		saveBtn:'none',
		nextBtn:'inline-block',
		content1:'none',
		content2:'none',
		content3:'none',
		content4:'none',
		content5:'none',
		content6:'none',
		addStatus:'block',
		data: {
			customerId: this.props.location.state?.customerId,
			selectBox: '1',
			basicInformation: {
				mileage: 100,

			}
		},
		dataKey: this.props.location.state?.dataKey ?? '1',
		devVisible:false, // 小窗口开关器-1
		listVisible:false, // 小窗口开关器-1
		backVisible:false, // 小窗口开关器-1
		verificationVisible:false, // 小窗口开关器-1
		verificationVisible_1:false, // 小窗口开关器-1
		listType:'',
		btnText:'检定',//按钮文字
    };
    // 报警功能及报警动作值
	formResParamenter2= async() =>{
		let resultOrderData = await this.forms4.onFinishNotReset();
		if(!resultOrderData) return false;
		var name ='operateValue';
		let value = parseFloat((
			Number(resultOrderData?.indicationOne) +
			Number(resultOrderData?.indicationTwo) +
			Number(resultOrderData?.indicationThree))/3).toFixed(1);
		this.forms44.setFieldsValue(name,value);
	};
	// 响应时间
	formResParamenter3= async() =>{
		let resultOrderData = await this.forms8.onFinishNotReset();
		if(!resultOrderData) return false;
		var name ='average';

		let value = parseFloat((
			Number(resultOrderData?.indicationOne) +
			Number(resultOrderData?.indicationTwo) +
			Number(resultOrderData?.indicationThree))/3).toFixed(2);

		this.forms8.setFieldsValue(name,value);
	};
	// 重复性(标准值40%LEL)
	formResParamenter4= async() =>{
		let resultOrderData = await this.forms9.onFinishNotReset();
		if(!resultOrderData) return false;
		var contNum = Number(resultOrderData.indicationOne)
			+Number(resultOrderData.indicationTwo)
			+Number(resultOrderData.indicationThree)
			+Number(resultOrderData.indicationFour)
			+Number(resultOrderData.indicationFive)
			+Number(resultOrderData.indicationSix);
		var avg = Number(contNum)/6;
		this.forms9.setFieldsValue('averageValue',avg);
		var sum = Math.pow((Number(resultOrderData.indicationOne) - avg),2)
		+ Math.pow((Number(resultOrderData.indicationTwo) - avg),2)
		+ Math.pow((Number(resultOrderData.indicationThree) - avg),2)
		+ Math.pow((Number(resultOrderData.indicationFour) - avg),2)
		+ Math.pow((Number(resultOrderData.indicationFive) - avg),2)
		+ Math.pow((Number(resultOrderData.indicationSix) - avg),2);
		var savg =  Math.sqrt(sum / 5) / avg;
		var repeatability = savg * 100;

		this.forms9.setFieldsValue('averageValue',Number(avg).toFixed(1));
		this.forms9.setFieldsValue('repeatability',Number(repeatability).toFixed(2));
		getRepeatability(resultOrderData).then(
			response=>{
				if(response?.data?.data){
					var {data} = response.data;
					// if(data?.averageValue) this.forms9.setFieldsValue('averageValue',data?.averageValue);
					// if(data?.repeatability) this.forms9.setFieldsValue('repeatability',data?.repeatability);
				}
			}
		)
	};
	// 零点飘移
	formResParamenter5= async() =>{
		let resultOrderData = await this.forms10.onFinishNotReset();
		if(!resultOrderData) return false;

		var zeroDrift =[];
		zeroDrift.push(Number(resultOrderData.indicationTwo - resultOrderData.indicationOne));
		zeroDrift.push(Number(resultOrderData.indicationThree - resultOrderData.indicationOne));
		zeroDrift.push(Number(resultOrderData.indicationFour - resultOrderData.indicationOne));
		zeroDrift.push(Number(resultOrderData.indicationFive - resultOrderData.indicationOne));
		zeroDrift.push(Number(resultOrderData.indicationSix - resultOrderData.indicationOne));
		zeroDrift.push(Number(resultOrderData.indicationSeven - resultOrderData.indicationOne));
		var maxData  = zeroDrift.reduce((a,b) =>{
			if(Math.abs(b) > Math.abs(a)){
				return b
			}else{
				return a
			}
		});
		// var max = Math.max.apply(null,zeroDrift);
		this.forms10.setFieldsValue('zeroIndication',parseFloat(maxData).toFixed(1) );
		// getZeroWander(resultOrderData).then(
		// 	response=>{
		// 		if(response?.data?.data){
		// 			var {data} = response.data;
		// 			console.log('零点飘移');
		// 			console.log(data);
		// 			// this.forms10.setFieldsValue('zeroIndication',data);
		// 		}
		// 	}
		// )
	};
	// 量程飘移
	formResParamenter6= async() =>{
		let resultOrderData = await this.forms10.onFinishNotReset();
		if(!resultOrderData) return false;
		let resultOrderData1 = await this.forms11.onFinishNotReset();
		if(!resultOrderData1) return false;

		var OneValue = resultOrderData1.indicationOne-resultOrderData.indicationOne;
		var TwoValue = resultOrderData1.indicationTwo-resultOrderData.indicationTwo;
		var threeValue = resultOrderData1.indicationThree-resultOrderData.indicationThree;
		var fourValue = resultOrderData1.indicationFour-resultOrderData.indicationFour;
		var fiveValue = resultOrderData1.indicationFive-resultOrderData.indicationFive;
		var sixValue = resultOrderData1.indicationSix-resultOrderData.indicationSix;
		var sevenValue = resultOrderData1.indicationSeven-resultOrderData.indicationSeven;
		var zeroDrift =[];
		zeroDrift.push(Number(TwoValue - OneValue));
		zeroDrift.push(Number(threeValue - OneValue));
		zeroDrift.push(Number(fourValue - OneValue));
		zeroDrift.push(Number(fiveValue - OneValue));
		zeroDrift.push(Number(sixValue - OneValue));
		zeroDrift.push(Number(sevenValue - OneValue));

		var maxData  = zeroDrift.reduce((a,b) =>{
			if(Math.abs(b) > Math.abs(a)){
				return b
			}else{
				return a
			}
		});

		// var max = Math.max.apply(null,zeroDrift);

		this.forms11.setFieldsValue('rangeIndication',parseFloat(maxData).toFixed(1) );

		// getMileageDrift({voMileageDrift:resultOrderData,voZeroWander:resultOrderData1}).then(
		// 	response=>{
		// 		if(response?.data?.data){
		// 			var {data} = response.data;
		// 			console.log(data);
		// 			this.forms11.setFieldsValue('rangeIndication',data);
		// 		}
		// 	}
		// )
	};
	// 示值误差*1
	formResParamenter7= async() =>{

		// 计算
		let resultOrderData = await this.forms5.onFinishNotReset();

		if(!resultOrderData) return false;
		let resultOrderData1 = await this.forms55.onFinishNotReset();
		if(!resultOrderData1) return false;
		var countNum = Number(resultOrderData1.indicationOne)+ Number(resultOrderData1.indicationTwo)+ Number(resultOrderData1.indicationThree);

		// this.forms555.setFieldsValue('averageValue',parseFloat(countNum/3).toFixed(2));
		this.forms555.setFieldsValue('averageValue',parseFloat(countNum/3).toFixed(1));

		this.forms555.setFieldsValue('indicationError',parseFloat((countNum/3)-resultOrderData.normalConcentration).toFixed(1));
		// this.forms555.setFieldsValue('indicationError',resultOrderData.normalConcentration);

	};
	// 示值误差*2
	formResParamenter8= async() =>{
		let resultOrderData = await this.forms6.onFinishNotReset();
		if(!resultOrderData) return false;
		let resultOrderData1 = await this.forms66.onFinishNotReset();
		if(!resultOrderData1) return false;
		// 计算
		var countNum = Number(resultOrderData1.indicationOne)+ Number(resultOrderData1.indicationTwo)+ Number(resultOrderData1.indicationThree);
		this.forms666.setFieldsValue('averageValue',parseFloat(countNum/3).toFixed(1));
		this.forms666.setFieldsValue('indicationError',parseFloat((countNum/3)-resultOrderData.normalConcentration).toFixed(1));

		// resultOrderData1.normalConcentration=resultOrderData.normalConcentration;
		// getIndicationError(resultOrderData1).then(
		// 	response=>{
		// 		console.log(resultOrderData1);
		// 		if(response?.data?.data){
		// 			var {data} = response.data;
		// 			this.forms666.setFieldsValue('averageValue',data.averageValue);
		// 			this.forms666.setFieldsValue('indicationError',data.indicationError);
		// 		}
		// 	}
		// )
	};
	// 示值误差*3
	formResParamenter9= async() =>{
		let resultOrderData = await this.forms7.onFinishNotReset();
		if(!resultOrderData) return false;
		let resultOrderData1 = await this.forms77.onFinishNotReset();
		if(!resultOrderData1) return false;
		var countNum = Number(resultOrderData1.indicationOne)+ Number(resultOrderData1.indicationTwo)+ Number(resultOrderData1.indicationThree);
		this.forms777.setFieldsValue('averageValue',parseFloat(countNum/3).toFixed(1));
		this.forms777.setFieldsValue('indicationError',parseFloat((countNum/3)-resultOrderData.normalConcentration).toFixed(1));

		// resultOrderData1.normalConcentration=resultOrderData.normalConcentration;
		// getIndicationError(resultOrderData1).then(
		// 	response=>{
		// 		console.log(resultOrderData1);
		// 		if(response?.data?.data){
		// 			var {data} = response.data;
		// 			this.forms777.setFieldsValue('averageValue',data.averageValue);
		// 			this.forms777.setFieldsValue('indicationError',data.indicationError);
		// 		}
		// 	}
		// )
	};
	async componentDidMount () {


		$('.loadBox').show();
		const {formData, id,rowVal,status,reason,alarmState} = this.props.location?.state ?? {};
		if(status){
			this.setState({addStatus:'none'})
		}

		console.log(rowVal);
		if(formData===true){
			this.setState({btnText:'核验'});
		}else{
			console.log('非核验');
			this.table1.refreshTable(this.state.tableParamenter.params);
			this.table2.refreshTable(this.state.tableTwoParamenter.params);
		}
		if(rowVal){
			console.log('检定');
			let data={basicInformation:{}};
			data.basicInformation=rowVal;
			data.basicInformation.type=null;
			this.setState({data});
			this.forms2.onModalClose();
			AlarmRecordInGasId({gasId: id}).then(
				response => {
					data.basicInformation.mileage='(3-100)%LEL';

					data.basicInformation.verifierDate=data?.verifierDate?moment(data.verifierDate):null;

					data.basicInformation.method=1;
					// data.basicInformation.type=1
					data.basicInformation.samplingEquipment=1;
					data.basicInformation.verificationBasis='JJG693-2011可燃气体检测报警器';
					// data.basicInformation.location='JJG693-中达信标准检测公司负一楼检测室';
					this.setState({data});
					this.forms2.onModalClose();

				}
			)
		}



		// 核验
		if(id){
			console.log('核验');
			AlarmRecordInGasId({gasId: id}).then(
				response => {
					const {data} = response.data;
					$('.loadBox').hide();
					if(data){
						// 上一层只有核验才传递数组
						if(!formData){this.setState({reasonSwitch:''})}
						// location
						// 驳回处理
						console.log('alarmState');
						console.log(rowVal?.alarmState)
						console.log('rowVal?.alarmState')
						if(rowVal?.alarmState!=="7"){
							this.AllFormDisabledTrue();
							this.table1.setDataTable(data?.measuringDevice);
							this.table2.setDataTable(data?.standardsAndInstallations);
						}
						this.setState({stepStatus:1});
						this.setState({stepBox1:'block'});
						this.setState({stepBox2:'block'});
						this.setState({btnBox:'block'});
						this.setState({saveBtn:'inline-block'});
						this.setState({nextBtn:'none'});
						// 控制展示内容
						if(data?.basicInformation.type===1){
							this.setState({content1:'block'});
							this.setState({content2:'block'});
							this.setState({content3:'block'});
							this.setState({content4:'block'});
							this.setState({content5:'block'});
							this.setState({content6:'block'})
						}else if(data?.basicInformation.type===2){
							this.setState({content1:'block'});
							this.setState({content2:'block'});
							this.setState({content3:'block'});
							this.setState({content4:'block'});
							this.setState({content5:'block'});
							this.setState({content6:'none'})
						}else if(data?.basicInformation.type===3){
							this.setState({content1:'block'});
							this.setState({content2:'block'});
							this.setState({content3:'block'});
							this.setState({content4:'block'});
							this.setState({content5:'none'});
							this.setState({content6:'none'});
						}
						this.setState({data});
						this.forms1.onModalClose();
						this.forms2.onModalClose();
						data.basicInformation.verifierDate=data?.verifierDate?moment(data.verifierDate):null;
						// this.forms2.setFieldsValue('verifierDate',data.verifierDate);
						this.forms2.setFieldsValue('location',data.location);




						var formTwoParamenter = this.state.formTwoParamenter;
						if(!formTwoParamenter.parameter.find(one=>{if(one.str==='检定地址'){return true}})){
							formTwoParamenter.parameter.push(
								{
									variableName: 'location',
									str: '检定地址',
									rules: TYPE_NAME.LABLE_NOT_NULL_50,
									disabled:rowVal?.alarmState==="7"?false:true
								},
							);
						}



						this.forms3.onModalClose();
						this.forms4.onModalClose();
						this.forms44.onModalClose();
						this.forms5.onModalClose();
						this.forms55.onModalClose();
						this.forms555.onModalClose();
						this.forms6.onModalClose();
						this.forms66.onModalClose();
						this.forms666.onModalClose();
						this.forms7.onModalClose();
						this.forms77.onModalClose();
						this.forms777.onModalClose();
						this.forms8.onModalClose();
						this.forms9.onModalClose();
						this.forms10.onModalClose();
						this.forms11.onModalClose();
					}

				}
			).catch(e=>{
				$('.loadBox').hide();
			})
		}

		// if(id) {
		// 	this.getData(id)
		// }
	}

	AllFormDisabledTrue=()=>{


		this.forms8.onModalClose();
		this.forms9.onModalClose();
		this.forms10.onModalClose();
		this.forms11.onModalClose();
		var {
			formParamenter,formTwoParamenter,
			formResParamenter1,formResParamenter2,formResParamenter22,
			formSzParamenter1,formSzParamenter11,formSzParamenter111,
			formSzParamenter2,formSzParamenter22,formSzParamenter222,
			formSzParamenter3,formSzParamenter33,formSzParamenter333,
			formResParamenter3,formResParamenter4,formResParamenter5,formResParamenter6
		} = this.state;
		formParamenter.parameter.map(one=>{one.disabled=true;});
		formTwoParamenter.parameter.map(one=>{one.disabled=true;});
		// formTwoParamenter.parameter[0].disabled=false;
		formResParamenter1.parameter.map(one=>{one.disabled=true;});
		formResParamenter2.parameter.map(one=>{one.disabled=true;});
		formResParamenter22.parameter.map(one=>{one.disabled=true;});
		formSzParamenter1.parameter.map(one=>{one.disabled=true;});
		formSzParamenter11.parameter.map(one=>{one.disabled=true;});
		formSzParamenter111.parameter.map(one=>{one.disabled=true;});
		formSzParamenter2.parameter.map(one=>{one.disabled=true;});
		formSzParamenter22.parameter.map(one=>{one.disabled=true;});
		formSzParamenter222.parameter.map(one=>{one.disabled=true;});
		formSzParamenter3.parameter.map(one=>{one.disabled=true;});
		formSzParamenter33.parameter.map(one=>{one.disabled=true;});
		formSzParamenter333.parameter.map(one=>{one.disabled=true;});
		formResParamenter3.parameter.map(one=>{one.disabled=true;});
		formResParamenter4.parameter.map(one=>{one.disabled=true;});
		formResParamenter5.parameter.map(one=>{one.disabled=true;});
		formResParamenter6.parameter.map(one=>{one.disabled=true;});
		this.setState({formParamenter})
	};

	//弹出表格格式化
	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-';

		// this.state.listType

		switch (value) {
			case 'edit':
				var data1= this.table1.getDataTable();// 刷新表格
				var data2= this.table2.getDataTable();// 刷新表格
				return <>
					{
						this.state.listType===1?
							data1?.length>=1?
								<div className={'tableNoLink'} title={'添加'}>
									<u>{'添加'}</u>
								</div>
								:
								<div className={'tableLink'} title={'添加'} onClick={() => this.addListData(rowData)}>
									<u>{'添加'}</u>
								</div>
							:
							data2?.length>=4?
								<div className={'tableNoLink'} title={'添加'}>
									<u>{'添加'}</u>
								</div>
								:
								<div className={'tableLink'} title={'添加'} onClick={() => this.addListData(rowData)}>
									<u>{'添加'}</u>
								</div>
					}

				{/*<div className={'tableLink'} title={'添加'} onClick={() =>*/}
				{/*	this.addListData(rowData)*/}
				{/*}*/}
				{/*>*/}
				{/*		<u>{'添加'}</u>*/}
				{/*</div>*/}
				</>;

			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	};
	//表单表格格式
	setFormTableValue = (value,text,rowData) => {
		if(text === 'null') return '-';
		switch (value) {
			case 'edit':
				return <>
				{this.props.location.state.status ?
				<div className={'tableNoLink'} title={'删除'}>
				<u>{'删除'}</u>
				</div>:
				<div className={'tableLink'} title={'删除'} onClick={() => this.removeListData(rowData.id)}>
				<u>{'删除'}</u>
				</div>
				}
				</>
			case 'expirationTime':

				var df=rowData.expirationTime?moment(rowData.expirationTime).format("YYYY-MM-DD"):moment(rowData?.effectiveTime).format("YYYY-MM-DD");

				return (<span>{df}</span>);
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}

	getData = id => {
		// AlarmRecordInGasId({gasId: id}).then(
		// 	response => {
		// 		const {data} = response.data;
		// 		console.log(data)
		// 	}
		// )
	}

    onSave = async () => {
		let value = await this.forms.onFinishNotReset()
		if(!value) return null
		value.id = this.state.data?.id
		value.locationId = this.state.data?.locationId
		value.areaName = localStorage.getItem('areaName')
		// value.mpAreaId = JSON.parse(localStorage.getItem('menus'))?.[0]
		Modal.confirm({
			title: `是否绑定到${localStorage.getItem('areaName')}?`,
			onOk: () => {
				// 新增计量点
				gasAlarmVerifierRecord(value).then(
					response => {
						let {data} = response.data
						if(data === true){
							message.success(value?.id ? "修改成功" : "新增成功")
							this.props.history.go(-1)
							funTraceInfo("报警器检定管理", "检定", null,value)

							// this.props.history.replace('/point/pointManagement')
						}else{
							message.error(data)
						}
					}
				)
			}
		})
    }
	//添加设备窗口显示
	addDevStatus=(devVisible)=>{
        this.setState({devVisible});
    };

	addDevData= async (value)=>{

		const {formData, id,customerId} = this.props.location?.state ?? {};
		let user = JSON.parse(sessionStorage.getItem("user"));
		let allFormData={};
		let{data}=this.state;
		allFormData.customerId=customerId;
		// 检定员
		// allFormData.verifier=user.realName;
		// allFormData.verifierId=user.id;
		allFormData.verifier='陈佳伟';
		allFormData.verifierId=126;
		allFormData.gasId=id;
		allFormData.result={};
		let sixDataResult=[{},{},{}];
			let environment = await this.forms1.onFinishNotReset();
			if(!environment) {
				this.changeStep(0);
				return null
			}
			allFormData.environment=environment;
			let basicInformation = await this.forms2.onFinishNotReset();
			if(!basicInformation) {
				this.changeStep(0);
				return null
			}
			allFormData.verifierDate=basicInformation?.verifierDate?moment(basicInformation?.verifierDate).format('YYYY-MM-DDTHH:mm:ss'):null;
			allFormData.basicInformation=basicInformation;
			let resultOrderData = await this.forms3.onFinishNotReset();
			if(this.state.content1==='block'){
				if(!resultOrderData) return null
			}

			allFormData.result = resultOrderData
			let warningFunction1 = await this.forms4.onFinishNotReset();
			let warningFunction2 = await this.forms44.onFinishNotReset();
			if(this.state.content2==='block'){
				if(!warningFunction1) return null
				if(!warningFunction2) return null
			}
			let warningFunction=Object.assign(warningFunction1, warningFunction2);
			allFormData.result.warningFunction=warningFunction
			let sixDataResult11 = await this.forms5.onFinishNotReset();
			let sixDataResult12 = await this.forms55.onFinishNotReset();
			let sixDataResult13 = await this.forms555.onFinishNotReset();
			if(this.state.content3==='block'){
			if(!sixDataResult11) return null
			if(!sixDataResult12) return null
			if(!sixDataResult13) return null
			}
			let sixDataResult1=Object.assign(sixDataResult11, sixDataResult12,sixDataResult13)
			sixDataResult[0]=sixDataResult1
			let sixDataResult21 = await this.forms6.onFinishNotReset()
			let sixDataResult22 = await this.forms66.onFinishNotReset()
			let sixDataResult23 = await this.forms666.onFinishNotReset()
			if(this.state.content3==='block'){
			if(!sixDataResult21) return null
			if(!sixDataResult22) return null
			if(!sixDataResult23) return null
			}
			let sixDataResult2=Object.assign(sixDataResult21, sixDataResult22,sixDataResult23)
			sixDataResult[1]=sixDataResult2
			let sixDataResult31 = await this.forms7.onFinishNotReset()
			let sixDataResult32 = await this.forms77.onFinishNotReset()
			let sixDataResult33 = await this.forms777.onFinishNotReset()
			if(this.state.content3==='block'){
			if(!sixDataResult31) return null
			if(!sixDataResult32) return null
			if(!sixDataResult33) return null
			}
			let sixDataResult3=Object.assign(sixDataResult31, sixDataResult32,sixDataResult33)
			sixDataResult[2]=sixDataResult3
		allFormData.result.sixDataResult = sixDataResult
			let responseTime = await this.forms8.onFinishNotReset()
			if(this.state.content4==='block'){
			if(!responseTime) return null
			}
			allFormData.result.responseTime=responseTime
			let repeatability = await this.forms9.onFinishNotReset()
			if(this.state.content5==='block'){
			if(!repeatability) return null
			}
			allFormData.result.repeatability=repeatability
			let zeroWander = await this.forms10.onFinishNotReset()
			if(this.state.content6==='block'){
			if(!zeroWander) return null
			}
			allFormData.result.zeroWander=zeroWander
			let mileageDrift = await this.forms11.onFinishNotReset()
			if(this.state.content6==='block'){
			if(!mileageDrift) return null
			}
			allFormData.result.mileageDrift=mileageDrift;
		allFormData.userId=JSON.parse(sessionStorage.getItem("user")).id;
		allFormData.location=basicInformation.location;
		if(formData===true)
		{
			console.log('核验');
			//核验：通过/驳回
			allFormData.certificateNumber=data.certificateNumber;
			allFormData.recordNumber=data.recordNumber;
			allFormData.verifierDate=data.verifierDate;
			// 核验人员
			// allFormData.check=user.realName;
			// allFormData.checkId=user.id;
			// allFormData.check='陈佳伟';
			// allFormData.checkId=126;
			allFormData.check='俞央立';
			allFormData.checkId=380;
			allFormData.verificationStatus=this.state.verificationStatus;
			allFormData.reason=this.state.reason;

			allFormData.measuringDevice=this.table1.getDataTable();
			allFormData.standardsAndInstallations=this.table2.getDataTable();
			// data.expirationTime=moment(data.expirationTime).format('YYYY-MM-DDT00:00:00');
			// data.nearFuture=moment(data.nearFuture).format('YYYY-MM-DDT00:00:00');
			allFormData.entrustId=this.props?.location?.state.rowVal?.entrustId;



			checkVerifierRecord(allFormData).then(data=>{
				if(data.data.data===true){
					message.success("添加成功");
					funTraceInfo("报警器检定管理", "核验", null,allFormData);

					// this.props.history.push({
					// 	pathname: "/verifvication/verificationMultifunctional",
					// 	state:{
					// 		page:this.props?.location?.state?.page
					// 	}
					// });

					if(this.props?.location?.state?.pathname){
						this.props.history.push({
							pathname: this.props?.location?.state?.pathname,
							state:{
								topFormItem_1_data:this.props?.location?.state?.topFormItem_1_data,
								id: this.props?.location?.state?.entrustId,
								page:this.props?.location?.state?.page,
								page1:this.props?.location?.state?.page1
							}
						});
					}else{
						this.props.history.go(-1)
					}

				}else{
					message.error('测试失败,'+data.data.responseInfo.description)
				}
			});
		}else{
			console.log('检定');
			// verifier:"盛浩"
			// verifierId:112
			// allFormData.verifier='盛浩';
			// allFormData.verifierId=112;
			var alarmState = this.props.location?.state?.alarmState;
			if(alarmState){
				allFormData.verificationStatus=alarmState === '1' ? '检定' : alarmState === '7' ? '驳回' :'-';
			}
			allFormData.entrustId=this.props?.location?.state.rowVal?.entrustId;
			gasAlarmVerifierRecord(allFormData).then(data=>{
				if(data.data.data===true){
					funTraceInfo("报警器检定管理", "检定", null,allFormData);
					message.success("添加成功");
					// this.props.history.push({
					// 	pathname: "/verifvication/verificationMultifunctional",
					// 	state:{
					// 		page:this.props?.location?.state?.page
					// 	}
					// });
					if(this.props?.location?.state?.pathname){
						this.props.history.push({
							pathname: this.props?.location?.state?.pathname,
							state:{
								topFormItem_1_data:this.props?.location?.state?.topFormItem_1_data,
								id: this.props?.location?.state?.entrustId,
								page:this.props?.location?.state?.page,
								page1:this.props?.location?.state?.page1
							}
						});
					}else{
						this.props.history.go(-1)
					}
				}else{
					message.error('检定失败,'+data.data.responseInfo.description)
				}
			});
		}

	};
	//添加列表窗口显示
	addListStatus=async(listVisible,listType)=>{
		let {data} = this.state;
		await this.setState({data});
		this.setState({listVisible,listType:listType});
		await this.table1.refreshTable();// 刷新表格
		await this.table2.refreshTable();// 刷新表格
		var data3=await this.table2.getDataTable();
		this.setVerNum();
		this.formResParamenter7();//示值误差*1
		this.formResParamenter8();//示值误差*2
		this.formResParamenter9();//示值误差*3
	};
	//添加配置
	addListData= async(rowData)=>{

		var data={};
		Object.assign(data,rowData);
		data.type=this.state.listType;
		data.cylinderId=data.id;
		data.id=null;
		data.expirationTime=moment(data.expirationTime).format('YYYY-MM-DDT00:00:00');
		data.nearFuture=moment(data.nearFuture).format('YYYY-MM-DDT00:00:00');
		data.userId=JSON.parse(sessionStorage.getItem("user")).id;
		var data1=await this.table1.getDataTable();// 刷新表格
		var data2=await this.table2.getDataTable();// 刷新表格

		if(data.type===1){
			if(data1?.length>=1){return message.info('不可在添加')}
		}else {
			if(data2?.length>=4){return message.info('不可在添加')}
		}
		updateCylinder(data).then(data=>{
			if(data.data.data===true){
				message.success("添加成功");
				funTraceInfo("报警器检定管理", "添加气瓶", null,rowData)
				this.table.refreshTable();// 刷新表格
			}else{
				message.error(data.data.responseInfo.description);
				this.table.refreshTable();// 刷新表格
			}
			this.table1.refreshTable();// 刷新表格
			this.table2.refreshTable();// 刷新表格

			this.setVerNum();

		}).catch(e=>message.info('异常问题，请重新操作'));

    };
	//删除配置
	removeListData= async (rowId)=>{
		deleteTemporaryGasCylinder({id:rowId}).then(data=>{
			if(data.data.data===true){
				message.success("删除成功")
				funTraceInfo("报警器检定管理", "删除气瓶配置", null,rowId)
				this.table1.refreshTable();// 刷新表格
				this.table2.refreshTable();// 刷新表格

			}else{
				message.error('删除失败')
			}
		});
    };

	//添加设备窗口显示
    clear = (e, info) => {
		let {formParamenter} = this.state
		this.setState({dataKey: e})
		this.setState({formParamenter})
    }
	changeStep=(status)=>{

		this.setState({stepStatus:status});
		if(status==0){
			this.setState({stepBox1:'block'});
			this.setState({stepBox2:'none'})
		}else{
			this.setState({stepBox1:'none'});
			this.setState({stepBox2:'block'})
		}
		if(status==1){
			this.setState({saveBtn:'inline-block'});
			this.setState({nextBtn:'none'})
		}else{
			this.setState({saveBtn:'none'});
			this.setState({nextBtn:'inline-block'})
		}
    };
	onChangeSelect=(status)=>{
		var formTwoParamenter = this.state.formTwoParamenter;
		var formResParamenter1 = this.state.formResParamenter1
		if(!formTwoParamenter.parameter.find(one=>{if(one.str==='检定地址'){return true}})){
			formTwoParamenter.parameter.push(
				{
					variableName: 'location',
					str: '检定地址',
					rules: TYPE_NAME.LABLE_NOT_NULL_50,
				},
			);
		}

		var data = this.state.data;

		if(status){
			this.setState({btnBox:'block'});
			if(status===1){

				formResParamenter1.parameter[3].rules= TYPE_NAME.REQ_NUMBER;
				// data.basicInformation.location = '中达信标准检测公司负一楼检测室';
				this.forms2.setFieldsValue('location','中达信标准检测公司负一楼检测室');
				this.setState({content1:'block'});
				this.setState({content2:'block'});
				this.setState({content3:'block'});
				this.setState({content4:'block'});
				this.setState({content5:'block'});
				this.setState({content6:'block'});
			}else if(status===2){
				formResParamenter1.parameter[3].rules= TYPE_NAME.NUMBER;
				// data.basicInformation.location = '中达信标准检测公司负一楼检测室';
				this.forms2.setFieldsValue('location','中达信标准检测公司负一楼检测室');
				this.setState({content1:'block'});
				this.setState({content2:'block'});
				this.setState({content3:'block'});
				this.setState({content4:'block'});
				this.setState({content5:'block'});
				this.setState({content6:'none'});
			}else if(status===3){
				formResParamenter1.parameter[3].rules= TYPE_NAME.NUMBER;
				// data.basicInformation.location = '用户使用现场';
				this.forms2.setFieldsValue('location','用户使用现场');
				this.setState({content1:'block'});
				this.setState({content2:'block'});
				this.setState({content3:'block'});
				this.setState({content4:'block'});
				this.setState({content5:'none'});
				this.setState({content6:'none'});
			}
			this.setState({formResParamenter1})
			// this.forms2.onFinishNotReset();
			// this.setState({data:data});
		}
    };
	toBackStatus=(backVisible)=>{
		this.setState({backVisible});
	};
	backPage=(backVisible)=>{
		this.setState({backVisible});
		if(this.props?.location?.state?.pathname){
			this.props.history.push({
				pathname: this.props?.location?.state?.pathname,
				state:{
					id: this.props?.location?.state?.entrustId,
					page:this.props?.location?.state?.page,
					page1:this.props?.location?.state?.page1,
					topFormItem_1_data:this.props?.location?.state?.topFormItem_1_data
				}
			});
		}else{
			this.props.history.go(-1)
		}


	};

	//
	setVerNum= async (data)=>{
		// var tableData1 = this.table1.getDataTable();
		var tableData2 = await  this.table2.getDataTable();
		this?.forms5.setFieldsValue('normalConcentration',null);
		this?.forms6.setFieldsValue('normalConcentration',null);
		this?.forms7.setFieldsValue('normalConcentration',null);
		if(!tableData2){
			return  null;
		}
		// 10
		if(tableData2[0]?.measuringRange){
			var value = (Number(tableData2[0].measuringRange.substr(0,tableData2[0].measuringRange.indexOf('E'))*0.01)
				/ (tableData2[0].detonationPoint/100) * 1)*100;
			this?.forms5.setFieldsValue('normalConcentration',	parseFloat(value).toFixed(1));
		}
		// 40
		if(tableData2[1]?.measuringRange){
			var value = (Number(tableData2[1].measuringRange.substr(0,tableData2[1].measuringRange.indexOf('E'))*0.01)
				/ (tableData2[1].detonationPoint/100) * 1)*100;
			this?.forms6.setFieldsValue('normalConcentration',	parseFloat(value).toFixed(1));
		}
		// 60
		if(tableData2[2]?.measuringRange){
			var value = (Number(tableData2[2].measuringRange.substr(0,tableData2[2].measuringRange.indexOf('E'))*0.01)
				/ (tableData2[2].detonationPoint/100) * 1)*100;
			this?.forms7.setFieldsValue('normalConcentration',	parseFloat(value).toFixed(1));
		}
		this.formResParamenter7();//示值误差*1
		this.formResParamenter8();//示值误差*2
		this.formResParamenter9();//示值误差*3

	};

    render() {


        return (
            <Layout className={'item_body_number_back tables orderPanl'}>
				<div className={'loadBox'} style={{zIndex:'9999'}}>
					<div className={'loadInner'}></div>
					<div className={'loadIcon'}>
						<Spin></Spin>
						<p>加载中...</p>
					</div>
				</div>
				<div style={{width: '100%',height: '100%',backgroundColor: '#ffffff',padding: '30px 1%'}}>
					<div className={'topTitleBox'} style={{height:'45px'}}>
						<h3>被检设备信息</h3>
						<div style={{float:'right'}}>
						<Button style={{margin:'0 0 0 20px'}} onClick={() => this.toBackStatus(true)}>返回</Button>
						</div>
					</div>
					<div className='stepsBox' style={{width: '100%',height:'80px', margin:'0 0 30px 20px'}}>
						<div style={{width: '40%',float:'left'}}>
							<div style={{fontSize:'16px',height:'30px'}}>
								<span style={{fontWeight: 'bold'}}>当前状态:</span><span style={{marginLeft:'10px'}}>{this.state.btnText}</span>
							</div>
							<div>
								<Steps size="small" current={this.state.stepStatus}>
									<Step title="被检设备信息" />
									<Step title="检定计量器具信息" />
								</Steps>
							</div>
						</div>
						<div style={{width: '40%',float:'left',marginLeft:'40px',display:this.state.reasonSwitch}}>
							<div>
								<span style={{fontWeight: 'bold'}}>驳回原因:</span>
							</div>
							<div>
								<TextArea style={{height:'80px',marginLeft:'60px' }}
										  maxLength={50}
										  value={this.state.reason}
										  disabled
										  autoSize={{ minRows: 3, maxRows: 8 }}
										  onChange={()=>{console.log(this.state.reasonSwitch)}}
								/>
							</div>
						</div>
					</div>

					<div style={{display:this.state.stepBox1}}>
						<div className={'topTitleBox'} style={{clear:'both'}}>
							<h3>环境条件</h3>
						</div>
						<div style={{clear:'both',padding:'0 10px'}}>
							<FormsTwo
								ref={e => this.forms1 = e}
								formParamenter={this.state.formParamenter}
								data={this.state.data?.environment}
							/>
						</div>
						<div className={'topTitleBox'} style={{clear:'both'}}>
							<h3>仪器基本信息</h3>
						</div>
						<div style={{clear:'both',padding:'0 10px'}}>
							<FormsTwo
								ref={e => this.forms2 = e}
								formParamenter={this.state.formTwoParamenter}
								data={this.state.data?.basicInformation}
							/>
						</div>
					</div>
					<div style={{display:this.state.stepBox2}}>
						<div id={'table1Element'} className={'topTitleBox'} style={{clear:'both'}}>
							<h3>检定用标准和装置</h3>
							<span style={{display:this.state.addStatus}} className={'addLink addList'} onClick={() => this.addListStatus(true,1)}><PlusSquareOutlined />新增</span>
						</div>
						<div style={{clear:'both',background:'#fff'}}>
							<TableComponents
							paramenter={this.state.tableParamenter}
							setData = {getTemporaryGasCylinderList1_1}
							setTableValue={this.setFormTableValue}
							ref={e => this.table1 = e}
						>
							</TableComponents>
						</div>
						<div id={'table2Element'}  className={'topTitleBox'} style={{clear:'both',paddingTop:'10px'}}>
							<h3>本次使用的计量器具<span style={{fontSize:'14px',color:'red'}}>(请按顺序添加器具)</span></h3>
							<span style={{display:this.state.addStatus}} className={'addLink addList'} onClick={() => this.addListStatus(true,2)}><PlusSquareOutlined />新增</span>
						</div>
						<div style={{clear:'both',background:'#fff'}}>
							<TableComponents
							paramenter={this.state.tableTwoParamenter}
							setData = {getTemporaryGasCylinderList2_1}
							setTableValue={this.setFormTableValue}
							ref={e => this.table2 = e}
						>
							</TableComponents>
						</div>
						<div className={'topTitleBox'} style={{clear:'both'}}>
							<h3>检定结果</h3>
						</div>
						<div style={{clear:'both',padding:'0 10px',display:this.state.content1}}>
							<FormsTwo
								ref={e => this.forms3 = e}
								formParamenter={this.state.formResParamenter1}
								data={this.state.data?.result}
							/>
						</div>
						<div style={{clear:'both',display:this.state.content2}}>
							<div className={'topTitleBox'} style={{clear:'both',display:this.state.content2}}>
								<h4>报警功能及报警动作值</h4>
							</div>
							<div style={{clear:'both',padding:'0 10px'}}>
								<FormsTwo
									ref={e => this.forms4 = e}
									formParamenter={this.state.formResParamenter2}
									data={this.state.data?.result?.warningFunction}
								/>
								<FormsTwo
									ref={e => this.forms44 = e}
									formParamenter={this.state.formResParamenter22}
									data={this.state.data?.result?.warningFunction}
								/>
								{/* <Form
								name="basic"
								labelCol={{ span: 7 }}
								wrapperCol={{ span: 16 }}
								initialValues={{ remember: true }}
								onFinish={this.onFinish}
								onFinishFailed={this.onFinishFailed}
								autoComplete="off"
								>
								<Row>
									<Col span={7}>
										<Form.Item label="报警功能">
											<Select name='warningFunction'>
											<Select.Option value="0">合格</Select.Option>
											<Select.Option value="1">不合格</Select.Option>
											</Select>
										</Form.Item>
									</Col>
									<Col span={10}>
										<Form.Item label="实测报警值(%LEL)" className={'listInput'}>
											<Input name='indicationOne' /> <Input name='indicationTwo' /> <Input name='indicationThree' />
										</Form.Item>
									</Col>
									<Col span={7}>
										<Form.Item label="报警动作值(%LEL)">
											<Input name='operateValue' />
										</Form.Item>
									</Col>
								</Row>
							</Form> */}
							</div>
						</div>
						<div style={{clear:'both',display:this.state.content3}}>
							<div className={'topTitleBox'} style={{clear:'both',display:this.state.content3}}>
								<h4>示值误差</h4>
							</div>
							<div style={{clear:'both',padding:'0 10px'}}>
								<FormsTwo
									ref={e => this.forms5 = e}
									formParamenter={this.state.formSzParamenter1}
									data={this.state.data?.result?.sixDataResult[0]}
								/>
								<FormsTwo
									ref={e => this.forms55 = e}
									formParamenter={this.state.formSzParamenter11}
									data={this.state.data?.result?.sixDataResult[0]}
								/>
								<FormsTwo
									ref={e => this.forms555 = e}
									formParamenter={this.state.formSzParamenter111}
									data={this.state.data?.result?.sixDataResult[0]}
								/>
							</div>
							<div style={{clear:'both',padding:'0 10px'}} className={'borderBox'}>
								<FormsTwo
									ref={e => this.forms6 = e}
									formParamenter={this.state.formSzParamenter2}
									data={this.state.data?.result?.sixDataResult[1]}
								/>
								<FormsTwo
									ref={e => this.forms66 = e}
									formParamenter={this.state.formSzParamenter22}
									data={this.state.data?.result?.sixDataResult[1]}
								/>
								<FormsTwo
									ref={e => this.forms666 = e}
									formParamenter={this.state.formSzParamenter222}
									data={this.state.data?.result?.sixDataResult[1]}
								/>
							</div>
							<div style={{clear:'both',padding:'0 10px'}}>
								<FormsTwo
									ref={e => this.forms7 = e}
									formParamenter={this.state.formSzParamenter3}
									data={this.state.data?.result?.sixDataResult[2]}
								/>
								<FormsTwo
									ref={e => this.forms77 = e}
									formParamenter={this.state.formSzParamenter33}
									data={this.state.data?.result?.sixDataResult[2]}
								/>
								<FormsTwo
									ref={e => this.forms777 = e}
									formParamenter={this.state.formSzParamenter333}
									data={this.state.data?.result?.sixDataResult[2]}
								/>
							</div>
						</div>
						<div style={{clear:'both',display:this.state.content4}}>
							<div className={'topTitleBox'} style={{clear:'both',display:this.state.content4}}>
								<h4>响应时间</h4>
							</div>
							<div style={{clear:'both',padding:'0 10px'}}>
								<FormsTwo
									ref={e => this.forms8 = e}
									formParamenter={this.state.formResParamenter3}
									data={this.state.data?.result?.responseTime}
								/>
							</div>
						</div>
						<div style={{clear:'both',display:this.state.content5}}>
							<div className={'topTitleBox'} style={{clear:'both'}}>
								<h4>重复性(标准值40%LEL)</h4>
							</div>
							<div style={{clear:'both',padding:'0 10px'}}>
								<FormsTwo
									ref={e => this.forms9 = e}
									formParamenter={this.state.formResParamenter4}
									data={this.state.data?.result?.repeatability}
								/>
							</div>
						</div>
						<div style={{clear:'both',display:this.state.content6}}>
							<div className={'topTitleBox'} style={{clear:'both'}}>
								<h4>零点漂移</h4>
							</div>
							{/* <div className='pyTableBox'>
							<table className='pyTable'>
								<thead>
									<th>时间</th>
									<th>0</th>
									<th>1h</th>
									<th>2h</th>
									<th>3h</th>
									<th>4h</th>
									<th>5h</th>
									<th>6h</th>
								</thead>
								<tbody>
									<tr>
										<td>零点漂移</td>
										<td><Input type={'number'} placeholder="请输入" /></td>
										<td><Input type={'number'} placeholder="请输入" /></td>
										<td><Input type={'number'} placeholder="请输入" /></td>
										<td><Input type={'number'} placeholder="请输入" /></td>
										<td><Input type={'number'} placeholder="请输入" /></td>
										<td><Input type={'number'} placeholder="请输入" /></td>
										<td><Input type={'number'} placeholder="请输入" /></td>
									</tr>
									<tr>
										<td>量程漂移</td>
										<td><Input type={'number'} placeholder="请输入" /></td>
										<td><Input type={'number'} placeholder="请输入" /></td>
										<td><Input type={'number'} placeholder="请输入" /></td>
										<td><Input type={'number'} placeholder="请输入" /></td>
										<td><Input type={'number'} placeholder="请输入" /></td>
										<td><Input type={'number'} placeholder="请输入" /></td>
										<td><Input type={'number'} placeholder="请输入" /></td>
									</tr>
								</tbody>
							</table>
							</div> */}
							<div style={{clear:'both',padding:'0 10px'}}>
								<FormsTwo
									ref={e => this.forms10 = e}
									formParamenter={this.state.formResParamenter5}
									data={this.state.data?.result?.zeroWander}
								/>
							</div>
							<div className={'topTitleBox'} style={{clear:'both'}}>
								<h4>量程漂移</h4>
							</div>
							<div style={{clear:'both',padding:'0 10px'}}>
								<FormsTwo
									ref={e => this.forms11 = e}
									formParamenter={this.state.formResParamenter6}
									data={this.state.data?.result?.mileageDrift}
								/>
							</div>
					</div>
					</div>
					{/*==============================================================================================*/}
					<div style={{textAlign:'center',padding:'0 0 20px',display:this.state.btnBox}}>
						<Button style={{margin:'0 0 0 20px',display:this.state.saveBtn}}
								onClick={() => this.changeStep(0)}>返回
						</Button>
						<Button style={{margin:'0 0 0 20px',display:this.state.nextBtn}} type="primary"
								onClick={() => {
							this.setVerNum();
							this.changeStep(1)
						}}>
							下一步
						</Button>
						<Button type="primary"
								danger
								style={{margin:'0 0 0 20px',display:this.props.location?.state?.formData?'':'none'}}
								onClick={() =>{


									this.setState({verificationVisible_1:true});
								}}>
							驳回
						</Button>

						<Popconfirm
							title="是否确定数据"
							description="是否确定数据"
							onConfirm={()=>{

								var data1 = this.table1.getDataTable();
								var data2 = this.table2.getDataTable();
								if(!data1 || data1?.length<1 ){
									let table1Element = document.getElementById('table1Element');
									// 如果对应id的锚点存在，就跳转到锚点
									if(table1Element) {
										table1Element.scrollIntoView({block: 'start', behavior: 'smooth'});
										return message.info('请选择检定用标准和装置');
									}
								}
								if(data2?.length<4){
									let table2Element = document.getElementById('table2Element');
									// 如果对应id的锚点存在，就跳转到锚点
									if(table2Element) {
										table2Element.scrollIntoView({block: 'start', behavior: 'smooth'});
										return message.info('请选择本次使用的计量器具的气瓶');
									}
								}
								// true=核验，false=检定
								if(this.props.location?.state?.formData){
									console.log('true');
									this.setState({verificationStatus:'核验'});
									this.addDevData();
								}else{
									console.log('false');
									this.addDevData();
								}
							}}
							// onCancel={cancel}
							okText="确定"
							cancelText="取消"
						>
							<Button style={{margin:'0 0 0 20px',display:this.state.saveBtn}} type="primary">
								{this.state.btnText}
							</Button>
						</Popconfirm>
					</div>
					{/*==============================================================================================*/}
					<Modal
                        title="添加"
                        style={{ top: 0 }}
                        visible={this.state.listVisible}
                        okText="确定"
                        onOk={() => this.addListStatus(false)}           // 点击确定
                        onCancel={() => this.addListStatus(false)}       // 点击取消
                        width={1200}
                        centered
                    >

                        <div>
							<div style={{width:'100%',height:'30px'}}>
								<div style={{width:'50%',}}>
									<span style={{width:'25%'}}>短名：</span>
									<Input value={this.state.remark}
										   style={{width:'55%'}}
										   onChange={(text)=>{this.setState({remark:text.target.value})}}/>
									<Button type={'primary'}
											onClick={()=>{
												var tableListParamenter = this.state.tableListParamenter;
												tableListParamenter.params.remark=this.state?.remark||null;
												tableListParamenter.params.page=1;
												this.table.refreshTable(tableListParamenter.params);// 刷新表格
											}}
									>
										查询
									</Button>
								</div>

							</div>
							<div style={{width:'100%', overflowY: 'auto',overflowX: 'auto'}}>
								<TableComponents
									paramenter={this.state.tableListParamenter}
									setData = {getCylinderList}
									setTableValue={this.setTableValue}
									ref={e => this.table = e}
								>
								</TableComponents>
							</div>

							{/*<TableComponents*/}
							{/*	paramenter={this.state.tableListParamenter}*/}
							{/*	setData = {getCylinderList}*/}
							{/*	setTableValue={this.setTableValue}*/}
							{/*	ref={e => this.table = e}*/}
							{/*>*/}
							{/*</TableComponents>*/}
                        </div>
                    </Modal>

					<Modal
                        title="提示"
                        style={{ top: 0 }}
                        visible={this.state.backVisible}
                        okText="确定"
                        onOk={() => this.backPage(false)}           // 点击确定
                        onCancel={() => this.toBackStatus(false)}       // 点击取消
                        width={400}
                        centered
                    >
                        <div style={{lineHeight:'60px',padding:'0 10px',textAlign:'center'}}>
							<h4>修改数据未保存确认返回吗？</h4>
                        </div>
                	</Modal>
					<Modal
						title="核验结果"
						style={{ top: 0 }}
						visible={this.state.verificationVisible}
						okText="确定"
						onOk={() => this.setState({verificationVisible:false})}           // 点击确定
						onCancel={() => this.setState({verificationVisible:false})}       // 点击取消
						width={400}
						centered
						footer={[
							<Button key="back"
									onClick={() => this.setState({verificationVisible:false})}>
								取消
							</Button>,
							<Button
								style={{"pointer-events": this.state.pointer_2}}
								type="primary" onClick={() => {
									this.setState({verificationVisible:false,verificationVisible_1:true});

								}}>
								驳回
							</Button>,
							<Button
								style={{"pointer-events": this.state.pointer_2}}
								type="primary" onClick={() => {
									this.setState({verificationVisible:false});
									this.setState({verificationStatus:'通过'});
									this.addDevData();
								}}>
								通过
							</Button>,
						]}
					>
						<div style={{lineHeight:'60px',padding:'0 10px',textAlign:'center'}}>
							<h4>是否核验通过？</h4>
						</div>
					</Modal>
					<Modal
						title="核验结果"
						style={{ top: 0 }}
						visible={this.state.verificationVisible_1}
						okText="确定"
						onOk={() => {
							this.setState({verificationStatus:'驳回'});
							this.addDevData();
							this.setState({verificationVisible_1:false})

						}}           // 点击确定
						onCancel={() => {this.setState({verificationVisible_1:false})}}       // 点击取消
						width={500}
						centered
					>
						<span>驳回原因</span>
						<TextArea style={{height:'80px'}}
								  maxLength={100}
								  autoSize={
									  { minRows: 3, maxRows: 8 }
								  }
								  onChange={(text)=>{
								  	this.setState({reason:text.target.value})
								  }}
						/>
					</Modal>
				</div>


            </Layout>
        )
    }
}
;
