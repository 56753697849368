import React, { Component } from 'react'
import {Layout, Popover, Table, Input, Radio, Divider, message, Tabs, Form, Select, Switch, Button} from 'antd'
import { CaretDownOutlined,ArrowLeftOutlined } from '@ant-design/icons'
import { Scrollbars } from 'react-custom-scrollbars'
import {getCustomerById} from '../../../services/customer'
import {getMeterById} from '../../../services/meter'
import {getRealTimeDataById} from '../../../services/meter'
import FormsText from '../../../components/common/forms/FormsText'
import moment from 'moment'
import {funTraceInfo} from '../../../services/common'
import TableComponents from '../../../components/common/tables/TableComponents'
import {getHistoricalEchartData,getDevicePageShowDataByTimeRangeAndContent,getHistoricalData,queryValveControlCommandByDevId,queryDeviceControlStatus,replyValveControlCommandByDevId,queryLeadSealRecord} from '../../../services/report'
import './business.less'
import $ from 'jquery'
import * as echarts from 'echarts/lib/echarts';
// 引入柱状图（这里放你需要使用的echarts类型 很重要）
import  'echarts/lib/chart/bar';
import  'echarts/lib/chart/pie';
import  'echarts/lib/chart/line';
import { TitleComponent,TooltipComponent,LegendComponent,ToolboxComponent,GridComponent } from 'echarts/components';
import FormsTwo from "../../../components/common/forms/FormsTwo";
import {TYPE_NAME} from "../../../components/Const";
echarts.use([TitleComponent,TooltipComponent,LegendComponent,ToolboxComponent,GridComponent]);


const { Option } = Select;
const FormItem = Form.Item;
const { Search } = Input;
const { TabPane } = Tabs;
const echartArry=[];
const echartTemperatureArry=[];
const echartPressureArry=[];
const echartCumulativeFlowArry=[];
const echartArryX=[];
const echartName='';
const tabIndex=0;
var myChart;
export default class Business extends Component {

    state = {
		loadingSwicth:false,
		checkedSwicth:false,



		taBDisabled:false,
		visible: false,
		statusName:'暂无',

		deviceData: {
			data: {},
			header: {
				// steelNum: '表钢号',
				// communication: '表具通讯号',
				state: '表具状态',
				dataTime: '数据时间',
				// communicateStatus: '通信状态',
				// lastCommunicationTime: '最后通信时间',
				simCard: 'sim卡号',
				temperature: '温度',
				pressure: '压力',
				signals: '信号',
				dumpEnergy: '剩余电量',
				value: '表盘示数',
				alarm: '报警信息',
				type: '阀门状态',
			}
		},
		userData: {
			data: {},
			header: {
				customerId: '客户号',
				organizationCode: '组织机构代码',
				name: '客户名称',
				legalPerson: '法人',
				legalPersonPhone: '法人手机号码',
				principal: '负责人',
				principalPhone: '负责人手机号码',
				mainBusiness: '主营业务',
				workTelephone: '单位电话',
				district: '客户所属辖区',
				province: '省',
				city: '市',
				district1: '行政区域',
				street: '街道',
				buildNum: '楼栋号',
				unitNum: '单元号',
				storeyNum: '楼层号',
				doorplateNum: '门牌号',
				address: '地址描述',
				bb:'',
				cc:''
			}
		},
		pointData: {
			data: {},
			header: {
				id: '计量点代码',
				purpose: '用气用途',
				properties: '用气性质',
				className: '用气设备',
				name: '计量点名称',
				type: '用气类型',
				contractNumber: '合同号',
				province: '省',
				city: '市',
				district: '行政区域',
				street: '街道',
				buildNum: '楼栋号',
				unitNum: '单元号',
				storeyNum: '楼层号',
				doorplateNum: '门牌号',
				address: '地址描述',
				installSite: '安装位置',
				position: '固定位置',
				mpBottom: '启用表底',
				state: '计量点状态',
				installTime: '安装日期',
			}
		},
		modalInfoData: {
			data: {},
			header: {
				modelName: '表具型号',
				type: '表具类型',
				minFlow: '最小流量',
				maxFlow: '最大流量',
				manufacture: '厂商简称',
				startFlow: '始动流量',
				serviceLife: '使用年限',
				protocolVersion: '协议型号',
			}
		},
		getRealTimeData: {
			data: {},
			header: {
				modelName: '表具型号',
				type: '表具类型',
				minFlow: '最小流量',
				maxFlow: '最大流量',
				manufacture: '厂商简称',
				startFlow: '始动流量',
				serviceLife: '使用年限',
				protocolVersion: '协议型号',
			}
		},
		// 表格参数
		tableParamenter: {
			manageName: "报表查询",
			// 是否绑定左侧区域
			bindArea: true,
			position: 'top',
            rowSelection: false,
            title: '表具历史数据查询报表',
			exportUrl: "/web/reportExportCtrl/exportHistoricalData",
			params:{page: 1,size: 15,steelNum:this.props.location.state.steelNum},
			// 表头：变量和变量解释
			header: {
                // company: '公司',
                // readUnit: '读表单元',
                // userName: '用户名称',
                // installNumber: '安装',
                // contractNumber: '合同',
                // meterId: '表具编号',
                // meterManufacturer: '表具厂商',
                // // protocolModel: '协议型号', // 新奥协议
                // modelName: '表具型号',
                // simCard: 'SIM卡号', // 1244
                collectionTime: '数据时间', // 数据时间 1183
                clockInTable: '表内时钟',
                pressure: '压力', // 1206
                standardFlow: '标况流量', // 瞬时流量 1248
                standardTotal: '标况总量', // 累积流量 1184
                workingFlow: '工况流量', // 工况瞬时流量 1266
                workingTotal: '工况总量', // 工况累积流量 1267
                temperature: '温度', // 1205
                signal: '信号量', // 1186
                voltageValue: '电压值', // 3000
                valveState: '阀门状态' // 1187
			},
			// 条件选择器参数
			conditionsParamenter: {
				parameter: [
					// {
					// 	type: 'input',
					// 	variableName: 'steelNum',
					// 	str: '表钢号'
					// },
					// {
					// 	type: 'input',
					// 	variableName: 'contractNum',
					// 	str: '合同号'
					// },
					// {
					// 	type: 'input',
					// 	variableName: 'readUnit',
					// 	str: '读表单元'
					// },
					{
						type: 'rangePicker',
						variableName: 'dataTime',
						time: true,
						str: '时间'
					}
				]
			},
		},
				// 表格参数
				cmdTableParamenter: {
					manageName: "报表查询",
					// 是否绑定左侧区域
					bindArea: true,
					rowSelection: false,
					// width: '83.7vw',
					params:{
						page: 1,
						size: 15,
						steelNum:this.props.location.state.steelNum,phoneNum:''},
					// 表头：变量和变量解释
					header: {

						content: '内容',
						forward: '方向',
						recTime: '时间',
						ip: 'IP',
						localPort: '端口',
						channelId: '通道号',
					},
					// 条件选择器参数
					conditionsParamenter: {
						parameter: [
							{
								type: 'rangePicker',
								variableName: 'dataTime',
								time: true,
								str: '时间'
							}
						]
					},
				},

		// controlType: '控制类型',
		// devId: '阀门唯一标识',
		// sendValue: '下发命令',
		// createTime: '操作时间',
		// updateTime: '完成时间',
		// createUserName: '操作人',
		// replyStatus: '状态',
		ycTable_params:{
			total:0,
			page: 1,
			size: 15,
			steelNum:this.props.location.state.steelNum,
			devId:this.props.location.state.devId
		},
		ycTable_form:{
			modal: true,
			number: 2,
			labelCol: 8,
			layout: "horizontal",
			// itemSize: 'large',
			data:[],
			parameter: [
				{
					variableName: 'dataTime1',
					str: '操作时间',
					rules: TYPE_NAME.DATE_PICKER,

				},
				{
					variableName: 'dataTime2',
					str: '完成时间',
					rules: TYPE_NAME.DATE_PICKER,

				},
			]
		},
		ycTable_loading:false,
		ycTable_columns:[
			{
				title: '控制类型',
				dataIndex: 'controlType',
				key: 'controlType',

			},
			{
				title: '阀门唯一标识',
				dataIndex: 'devId',
				key: 'devId'
			},
			{
				title: '下发命令',
				dataIndex: 'sendValue',
				key: 'sendValue'
			},
			{
				title: '操作时间',
				dataIndex: 'createTime',
				key: 'createTime',
				render: (text) => {
					if(!text){return null;}
					text=moment(text).format("yyyy-MM-DD HH:mm:ss");
					return(
						<span>{text}</span>
					);
				}
			},
			{
				title: '完成时间',
				dataIndex: 'updateTime',
				key: 'updateTime',
				render: (text) => {
					if(!text){return null;}
					text=moment(text).format("yyyy-MM-DD HH:mm:ss");
					return(
						<span>{text}</span>
					);
				}
			},
			{
				title: '操作人',
				dataIndex: 'createUserName',
				key: 'createUserName'
			},
			{
				title: '状态',
				dataIndex: 'replyStatus',
				key: 'replyStatus'
			},
		],
		ycTable_data:[],
				// 远程控制表格参数
				ycTableParamenter: {
					manageName: "报表查询",
					// 是否绑定左侧区域
					bindArea: true,
					rowSelection: false,
					title: '历史操作记录',
					params:{page: 1,size: 15,steelNum:this.props.location.state.steelNum,devId:this.props.location.state.devId},
					// 表头：变量和变量解释
					header: {

						controlType: '控制类型',
						devId: '阀门唯一标识',
						sendValue: '下发命令',
						createTime: '操作时间',
						updateTime: '完成时间',
						createUserName: '操作人',
						replyStatus: '状态',
					},
					// 条件选择器参数
					conditionsParamenter: {
						parameter: [
							{
								type: 'datePicker',
								variableName: 'dataTime1',
								time: true,
								str: '操作时间'
							},
							{
								type: 'datePicker',
								variableName: 'dataTime2',
								time: true,
								str: '完成时间'
							},
							// {
							// 	type: 'select',
							// 	variableName: 'state',
							// 	str: '状态',
							// 	data: [
							// 		{
							// 			key: '1',
							// 			value: '全部'
							// 		},
							// 		{
							// 			key: '2',
							// 			value: '待开阀'
							// 		},
							// 		{
							// 			key: '3',
							// 			value: '待关阀'
							// 		},
							// 		{
							// 			key: '4',
							// 			value: '开阀完成'
							// 		},
							// 		{
							// 			key: '5',
							// 			value: '关阀完成'
							// 		}
							// 	]
							// },
						]
					},
				},
    };

    getdata33=()=>{

    	return console.log('342');

	};


	// 条件选择：获取输入的条件
	getConditionsValue = ({dataTime}) => {
		let sTime=dataTime ? moment(dataTime[0]._d).format("yyyy-MM-DD HH:00:00") : null;
		let eTime=dataTime ? moment(dataTime[1]._d).format("yyyy-MM-DD HH:00:00") : null;
		console.log()
		if(this.tabIndex==2)
		{
			myChart.dispose();
			if(sTime){
				this.getEchartList(this.props.location.state.steelNum,sTime,eTime);
				setTimeout(() => {
					this.echartArry=this.echartTemperatureArry;
					this.getPie();
				},1000);
			}else{
				this.getEchartList(this.props.location.state.steelNum,'','');
				setTimeout(() => {
					this.echartArry=this.echartTemperatureArry;
					this.getPie();
				},1000);
			}
		}



		return {
			startTime: dataTime ? moment(dataTime[0]._d).format("yyyy-MM-DD HH:00:00") : null,
			endTime: dataTime ? moment(dataTime[1]?._d).format("YYYY-MM-DD HH:00:00") : null
		}

	};

	setTableValue = (value,text,rowData) => {

		switch (value) {
				case 'content':
					return text ? <div style={{textAlign:'left'}} title={text}>{text}</div> : ''
			case 'forward':
					text = text*1;
					return text === 0 ? '上行' : text === 1 ? '下行' : '';
			case 'createTime':
				text =moment(text).format('YYYY-MM-DD HH:mm:ss');
				return(
					<span>{text}</span>
				);
			case 'updateTime':
				if(!text){
					return null;
				}
				// let datas= this.table2?.getDataTable();
				// if(datas.length!==0) {
				// 	if (!datas[0].updateTime) {
				// 		if(datas[0].controlType==='开阀'){
				// 			return this.setState({checkedSwicth:true});
				// 		}else {
				// 			return this.setState({checkedSwicth:false});
				// 		}
				// 	}else{
				// 		this.setState({checkedSwicth:this.state.statusName==='开阀'?true:false});
				// 	}
				// }
				text =moment(text).format('YYYY-MM-DD HH:mm:ss');
				return(
					<span>{text}</span>
				);
				default:
					return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}


	componentDidMount(){

		this.getEchartList(this.props.location.state.steelNum,'','');
		const {meterId} = this.props.location.state ?? {};
		this.getMeterInfo(meterId);
		this.echartName='温度';
		console.log(this.props.location.state.devId)
		queryDeviceControlStatus({devId:this.props.location.state.devId}).then(
			response => {
				const {data} = response.data;
				var checkedSwicth = this.state.checkedSwicth;
				if(data){
					this.state.statusName=data?.defaultValue?data?.defaultValue:'关阀';

					if(data?.defaultValue){
						checkedSwicth=data?.defaultValue.indexOf('关闭')!==-1?false:true;

					}else{
						checkedSwicth=false;
					}
					console.log('checkedSwicth');
					console.log(checkedSwicth);

					this.setState({checkedSwicth:checkedSwicth})
					// if(data.defaultValue==='ON')
					// {
					// 	this.state.statusName='开'
					// }else{
					// 	this.state.statusName='关'
					// }
				}
				if(response.data?.responseInfo?.description.indexOf('该设备不具备阀控操作')!==-1){
					this.setState({taBDisabled:true})
				}else{

					this.setState({taBDisabled:false})
				}
			}
		);

		$(document).on('click', '.smallImg', function() {
			var url=$(this).attr('src')
			$('.bigImg').attr('src',url)
			$('.bigImgBox').show()
		});
		$(document).on('click', '.bigImgBox', function() {
			$('.bigImgBox').hide()
		});

		this.queryValveControlCommandByDevId();// 获取操作列表第一条数据判断开关阀状态

	}
	// 获取操作列表第一条数据判断开关阀状态
	 queryValveControlCommandByDevId= async data =>{
		var params=this.state.ycTable_params;
		if(data?.dataTime1){
			params.dataTime1=moment(data?.dataTime1).format('YYYY-MM-DD HH:mm:ss')
		}
		 if(data?.dataTime2){
			 params.dataTime2=moment(data?.dataTime2).format('YYYY-MM-DD HH:mm:ss')
		 }
		this.setState({ycTable_loading:true});
		queryValveControlCommandByDevId(this.state.ycTable_params).then(
			response => {
				if(response?.data?.data){
					var data = response.data.data;
					if(!data[0]?.updateTime){
						if(data[0]?.controlType.indexOf('开阀')!==-1){
							this.setState({checkedSwicth:true});
						}else {
							this.setState({checkedSwicth:false});
						}
					}else {
						this.setState({checkedSwicth:this.state.statusName.indexOf('开')!==-1?true:false});
					}
					var params = this.state.ycTable_params;
					params.total=response?.data?.pageInfo.total;

					this.setState({ycTable_data:data,ycTable_params:params})
				}else {
					this.setState({checkedSwicth:this.state.statusName.indexOf('开')!==-1?true:false});
				}
				this.setState({ycTable_loading:false});
			}
		).catch((e)=>{
			message.info('获取数据异常，请刷新页面');
			this.setState({checkedSwicth:this.state.statusName.indexOf('开')!==-1?true:false});
		})
	};


	getEcharts = (key,e) => {
		this.tabIndex=key
		let steelNum=this.props.location.state.steelNum
        if(key*1 === 2) {
			this.getPie();
			if(this.echartArry)
			{
				console.log('')
			}else{
				this.echartArry=this.echartTemperatureArry;
			}
		}else if(key*1 === 5) {
			queryLeadSealRecord({stencilNum:steelNum}).then(
				response => {
					const {data} = response.data
					$('#leadSealList').html('')
					if(data){
						let num=0
						if(data.length>0){
							for(var i=0;i<data.length;i++){
								num++
								var imgUrl=''
								if(data[i].pathList)
								{
									if(data[i].pathList.length>0){
										for(var j=0;j<data[i].pathList.length;j++){
											imgUrl+="<img class='smallImg' alt='暂未上传' src="+data[i].pathList[j]+" />"
										}
									}

								}

								$('#leadSealList').append("<div class='installInnerSmall'>"+
								"<p>铅封"+num+"</p>"+
								"<ul>"+
									"<li>铅封号：<span>"+data[i].leadSealNum+"</span></li>"+
									"<li>操作人：<span>"+data[i].sponsor+"</span></li>"+
									"<li>操作时间：<span>"+data[i].createTime+"</span></li>"+
									"<li>操作类型：<span>"+data[i].type+"</span></li>"+
									"<li>铅封状态：<span>"+data[i].status+"</span></li>"+
								"</ul>"+
								"<div class='installInnerImgBox'>"+imgUrl+
								"</div>"+
								"</div>")
							}
						}else{
							$('#leadSealList').html('<p class="installAltText">暂无数据</p>');
						}

					}

				}
			)
		}

    }
	onEchartsChange = (value) => {
		myChart.dispose();
		if(value*1==1){
			this.echartName='温度';
			this.echartArry=this.echartTemperatureArry;
			this.getPie();
		}else if(value*1==2){
			this.echartName='压力';
			this.echartArry=this.echartPressureArry;
			this.getPie();
		}else if(value*1==3){
			this.echartName='用量';
			this.echartArry=this.echartCumulativeFlowArry;
			this.getPie();
		}
	}
	getPie =  () => {
		setTimeout(() => {
		// 基于准备好的dom，初始化echarts实例
		myChart = echarts.init(document.getElementById('pie1'));
		// 绘制图表
		myChart.setOption({
			title: {
				text: '近20天'+this.echartName+'曲线视图'
			  },
			  tooltip: {
				trigger: 'axis'
			  },
			//   legend: {
			// 	data: ['数据一']
			//   },
			  grid: {
				left: '3%',
				right: '4%',
				bottom: '3%',
				containLabel: true
			  },
			  toolbox: {
				// feature: {
				//   saveAsImage: {}
				// }
			  },
			  xAxis: {
				type: 'category',
				boundaryGap: false,
				data: this.echartArryX
			  },
			  yAxis: {
				type: 'value'
			  },
			  series: [
				{
				  name: this.echartName,
				  type: 'line',
				  stack: 'Total',
				  smooth: true,
				  data: this.echartArry
				}
				// {
				//   name: '数据二',
				//   type: 'line',
				//   stack: 'Total',
				//   smooth: true,
				//   data: [120, 132, 101, 134, 90, 230, 210]
				// },
				// {
				//   name: '数据三',
				//   type: 'line',
				//   stack: 'Total',
				//   smooth: true,
				//   data: [120, 132, 101, 134, 90, 230, 210]
				// }
			  ]
		});
	},100)
}

	getCustomerInfo = customerId => {
		if(!customerId) return message.warning('没有绑定客户')
		getCustomerById({customerId: customerId}).then(
			response => {
				let {userData} = this.state
				let {data={}} = response.data
				userData.data = data
				this.setState({userData})
			}
		)
	};

	getMeterInfo = async meterId => {
		getMeterById({meterId: meterId}).then(
			response => {
				let {pointData,cmdTableParamenter,ycTableParamenter} = this.state;
				let {data={}} = response?.data;
				if(response?.data?.data){
					var datas = response?.data?.data;
					datas?.data.map(one=>{
						if(one.itemId==='1513'){
							data.type=one.value;
						}
					});
				}
				if(data?.customerId) this.getCustomerInfo(data.customerId);
				pointData.data = data;

				cmdTableParamenter.params.phoneNum=pointData.data?.phoneNum??this.props.location.state.steelNum;
				ycTableParamenter.params.devId=pointData.data?.devId;
				this.setState({pointData,cmdTableParamenter,ycTableParamenter});
				// this.state.cmdTableParamenter.params.phoneNum=9505047638042;
			}
		)
	};

	getEchartList = async (steelNum,startTime,endTime) => {
		let menusStr = localStorage.getItem('menus');
        let menus = ((menusStr != null || menusStr != "null") && typeof menusStr == 'string') ? JSON.parse(localStorage.getItem('menus')) : []
		getHistoricalEchartData({page: 1,size: 99999,areaIds: menus,steelNum: steelNum,startTime:startTime,endTime:endTime}).then(
		// getHistoricalEchartData({page: 1,size: 99999,areaIds: [252],steelNum: 'A16151122164000',startTime:startTime,endTime:endTime}).then(
			response => {
				console.log(response.data);
				if(response.data[0]){
					if(response.data[0].temperature.length>0)
					{
						this.echartTemperatureArry=[];
						for(var i=0;i<response.data[0].temperature.length;i++)
						{
							this.echartTemperatureArry.push(parseFloat(response.data[0].temperature[i]).toFixed(2));
						}
					}else{
						this.echartTemperatureArry=[];
					}
					if(response.data[0].pressure.length>0)
					{
						this.echartPressureArry=[];
						for(var i=0;i<response.data[0].pressure.length;i++)
						{
							this.echartPressureArry.push(parseFloat(response.data[0].pressure[i]).toFixed(2));
						}
					}else{
						this.echartPressureArry=[];
					}
					if(response.data[0].cumulativeFlow.length>0)
					{
						this.echartCumulativeFlowArry=[];
						for(var i=0;i<response.data[0].cumulativeFlow.length;i++)
						{
							this.echartCumulativeFlowArry.push(parseFloat(response.data[0].cumulativeFlow[i]).toFixed(2));
						}
					}else{
						this.echartCumulativeFlowArry=[];
					}
					this.echartArryX=response.data[0].xData;
				}

			}
		)
	};
	// 开关
	onSwitch = async checked => {
		console.log(checked);
		let user = JSON.parse(sessionStorage.getItem("user"));
		var staNum=1;
		if(checked===false){
			staNum=2
		}else{
			staNum=1
		}
		this.setState({loadingSwicth:true});
		replyValveControlCommandByDevId({
			devId:this.props.location.state.devId,
			createUserId:user.id,
			createUserName:user.realName,sheetSteelGrade:this.props.location.state.steelNum,
			status:staNum}).then(
			response => {
				const {data} = response.data;
				if(data){
					if(data==='下发成功'){
						// funTraceInfo("表具信息管理", "下发", null,null);
						// this.table.refreshTable(this.state.ycTableParamenter.params);
						// let datas= this.table2?.getDataTable();

						message.success(data);
						this.setState({checkedSwicth:checked});
					}else{
						message.error(data);
					}
					this.queryValveControlCommandByDevId();
				}
				this.setState({loadingSwicth:false});
			}
		)
		// 	.catch((e)=>{
		// 	message.error('下发异常，请重新操作');
		// })
	  };

	handleVisibleChange = visible => this.setState({ visible })

	onSearch = value => console.log(value)

	// 跳转到装表
	install = () => {
		let point = this.state.pointData.data
		if(point?.id) return message.warning('该表已被绑定,不能进行安装')
		this.props.history.push({
			pathname: '/meter/meterInstall',
			state: point
		})
	}

    render() {

		const text = <Search placeholder="输入表钢号" onSearch={this.onSearch} enterButton />

		const components = {
            table (props) {
                const { className } = props;
                return (
                    <Scrollbars style={{ height: '40vh'}} >
                        <table className={className} style={{overflowX: 'hidden'}}>
                        	{ props.children }
                        </table>
                    </Scrollbars>
                )
            }
        };

		const columns = [
			{
			  title: '表钢号',
			  dataIndex: 'steelNumber',
			  key: 'steelNumber',
			  align: 'center',
			},
			{
			  title: '安装位置',
			  dataIndex: 'installSite',
			  align: 'center',
			  key: 'installSite',
			}
		  ];

		const data = [
			{
				id: 1,
				steelNumber: '1',
				installSite: '雨花区',
			},
			{
				id: 2,
				steelNumber: '2',
				installSite: '岳麓区',
			},
			{
				id: 3,
				steelNumber: '3',
				installSite: '开福区',
			},
			{
				id: 4,
				steelNumber: '4',
				installSite: '天心区',
			},
			{
				id: 5,
				steelNumber: '5',
				installSite: '雨花区',
			},
			{
				id: 6,
				steelNumber: '6',
				installSite: '芙蓉区',
			},
			{
				id: 7,
				steelNumber: '7',
				installSite: '望城区',
			},
		];

		const content = (
			<div className={'insert_table'} style={{width: '100%'}}>
				<Table
					scroll={{ y: '40vh' }}
					bordered={true}
					columns={columns}
					rowKey={'id'}
					className={'left_title_table'}
					dataSource={data}
					pagination={false}
					components={components}
				/>
			</div>
		);


        return (
            <Layout className={'item_body_number_back'} style={{backgroundColor: '#ffffff'}}>
				<div style={{padding: '0.5% 0',position: 'relative'}}>
					<div className={'left_title font_size2'} style={{position: 'absolute',marginLeft: '1%', width: '40vw'}}>
						<span className={'back_arrow'} onClick={() => this.props.history.go(-1)}><ArrowLeftOutlined /></span>&nbsp;
						{this.props.location.state ? this.props.location.state.steelNum : '未选择表钢号'}
						{/* <Popover
							content={content}
							title={text}
							placement="bottomLeft"
							trigger="click"
							visible={this.state.visible}
							onVisibleChange={this.handleVisibleChange}
						>
							<CaretDownOutlined/>
						</Popover> */}
					</div>
					<div style={{float:'right',margin:'0 10px 0 0'}}>
						<Radio.Group defaultValue="install" buttonStyle="solid">
							{/*<Radio.Button value="install" onClick={this.install}>安装</Radio.Button>*/}
							{/* <Radio.Button value="change">换表</Radio.Button>
							<Radio.Button value="dismantle">拆表</Radio.Button>
							<Radio.Button value="stop">停表</Radio.Button>
							<Radio.Button value="reuse">复用</Radio.Button>
							<Radio.Button value="scrapped">报废</Radio.Button> */}
						</Radio.Group>
					</div>
				</div>

				<div style={{height: '100%',margin: '0 15px',overflow:'auto'}}>
					<Tabs defaultActiveKey="1" onTabClick={this.getEcharts}>
						<TabPane tab="基本信息" key="1">
							<div className={'insert_table'}>
								<div className={'cardList'}>
								<Divider style={{margin: '3px 0'}}>实时数据</Divider>
								<FormsText
									record = {this.state.pointData.data}
									header = {this.state.deviceData.header}
									rowLen = {4}
								/>
								</div>
								<div className={'cardList'}>
								<Divider style={{margin: '3px 0'}}>表具信息</Divider>
								<FormsText
									record = {this.state.pointData.data}
									header = {this.state.modalInfoData.header}
									rowLen = {4}
								/>
								</div>
								<div className={'cardList'}>
								<Divider style={{margin: '3px 0'}}>计量点信息</Divider>
								<FormsText
									record = {this.state.pointData.data}
									header = {this.state.pointData.header}
									rowLen = {4}
								/>
								</div>
								<div className={'cardList'}>
								<Divider style={{margin: '3px 0'}}>客户信息</Divider>
								<FormsText
									record = {this.state.userData.data}
									header = {this.state.userData.header}
									rowLen = {4}
								/>
								</div>
							</div>
						</TabPane>
						{/* <TabPane tab="实时数据" key="2">
							<div className={'insert_table'}>
								<Divider style={{margin: '3px 0'}}>实时数据</Divider>
								<FormsText
									record = {this.state.userData.data}
									header = {this.state.userData.header}
									rowLen = {4}
								/>
							</div>
						</TabPane> */}
{/*历史数据-----------------------------------------------*/}
						<TabPane tab="历史数据" key="2">
							<div className={'historicalTabel'}>
								<TableComponents
									paramenter={this.state.tableParamenter}
									setData={getHistoricalData}
									getConditionsValue={this.getConditionsValue}
									// setTableValue={this.setTableValue}
									ref={e => this.table = e}
								>
									<FormItem label={"区域"}>
										<Input disabled value={localStorage.getItem('areaName')} size='middle'/>
									</FormItem>
								</TableComponents>
							</div>
							<div style={{margin:'20px 0 0 0',width:'300px'}}>
								<Form.Item name="gender" label="图表数据源">
									<Select
									placeholder="温度"
									onChange={this.onEchartsChange}
									allowClear
									>
									<Option value="1">温度</Option>
									<Option value="2">压力</Option>
									<Option value="3">用量</Option>
									</Select>
								</Form.Item>
							</div>
							<div id="pie1" style={{height: '300px',margin:'20px 0 0 0'}}></div>
						</TabPane>
{/*CMD-----------------------------------------------*/}
						<TabPane tab="CMD" key="3">
								<TableComponents
									paramenter={this.state.cmdTableParamenter}
									setData={getDevicePageShowDataByTimeRangeAndContent}
									getConditionsValue={this.getConditionsValue}
									setTableValue={this.setTableValue}
									ref={e => this.table = e}
								>
								</TableComponents>
						</TabPane>
{/*远程控制-----------------------------------------------*/}
						<TabPane tab="远程控制" key="4"
								 disabled={this.state.taBDisabled}
						>
							<p><span style={{marginLeft:'10px'}}>阀门类型：内置阀</span>
								<span style={{marginLeft:'20px'}}>阀门状态：{this.state.statusName}</span>
								<div style={{float:'right',marginRight:'10px'}}>
									<span>开/关：</span>
									<Switch
										checkedChildren="开启" unCheckedChildren="关闭"
										loading={this.state.loadingSwicth}
										checked={this.state.checkedSwicth}
										onChange={(checked)=>{this.onSwitch(checked)}}
									/>
								</div></p>
							<div style={{width:'100%',height:'50px', textAlign: 'center'}}>
								<spen style={{fontSize:'24px', fontWeight: 'bold'}}>历史操作记录</spen>
							</div>
							<div style={{width:'100%',height:'40px'}}>
								<div style={{width:'35%',float:'left'}}>
									<FormsTwo
										ref={e => this.forms4 = e}
										formParamenter={this.state.ycTable_form}
										data={this.state.ycTable_form.data}
									/>
								</div>
								<div style={{width:'6%',float:'left',paddingLeft:'10px'}}>
									<Button  type="primary" onClick={async()=>{
										this.queryValveControlCommandByDevId(await this.forms4.onFinishNotReset())
									}}>查询</Button>
								</div>
								<div style={{width:'6%',float:'left',paddingLeft:'10px'}}>
									<Button onClick={()=>{
										this.forms4.onModalClose();
										var params=this.state.ycTable_params;
										params.dataTime1=null;
										params.dataTime2=null;
										this.queryValveControlCommandByDevId()
									}}>清空</Button>
								</div>
								<div style={{width:'10%',float:'left',paddingLeft:'47%'}}>
									<Button type="primary"  onClick={async()=>{
										this.queryValveControlCommandByDevId(await this.forms4.onFinishNotReset())
									}}>刷新</Button>
								</div>
							</div>
								<Table
									bordered
									loading={this.state.ycTable_loading}
									columns={this.state.ycTable_columns}
									dataSource={this.state.ycTable_data}
									pagination = {{
										showSizeChanger:true,
										showQuickJumper: true,
										onChange:(page, pageSize)=>{
											var params = this.state.ycTable_params;
											params.page=page;
											console.log(1);
											this.queryValveControlCommandByDevId();
											this.setState({ycTable_params:params})
										},       // 获得翻页数值
										total:this.state.ycTable_params.total,
										pageSize:15,
										showTotal: (count = this.state.ycTable_params.total)=>{
											return '共'+this.state.ycTable_params.total+'条数据'
										},
										defaultCurrent: 1,
										pageSizeOptions: ['15']
									   }}
							/>
								{/*<TableComponents*/}
								{/*	paramenter={this.state.ycTableParamenter}*/}
								{/*	setData={queryValveControlCommandByDevId}*/}
								{/*	getConditionsValue={this.getConditionsValue}*/}
								{/*	setTableValue={this.setTableValue}*/}
								{/*	ref={e => this.table2 = e}*/}
								{/*>*/}
								{/*</TableComponents>*/}
						</TabPane>

						<TabPane tab="铅封" key="5">
							<div id='leadSealList'>

							</div>
						</TabPane>
					</Tabs>
				</div>
				<div className={'bigImgBox'}>
						<div className={'bigImgBoxBg'}></div>
						<img className={'bigImg'}></img>
				</div>
            </Layout>
        )
    }
}
