import React, { Component } from 'react'
import { Layout,Menu } from "antd"
import { NavLink, withRouter } from "react-router-dom"
import { UserOutlined,ClockCircleOutlined,PoweroffOutlined,MenuUnfoldOutlined,CloseOutlined ,PieChartOutlined} from '@ant-design/icons';
import $ from 'jquery'

import xinaoLogo from '../../../assets/xinao.png'
import hdstLogo from '../../../assets/hdst.png'
import {logout} from "../../../services/auth";

const { Header} = Layout;
const { SubMenu } = Menu;

class LogoMenu extends Component {

    state = {
        dates: new Date().toLocaleString(),
        logoText:(JSON.parse(sessionStorage.getItem("loginType"))===3
            ?'长沙新奥数据分析与管理系统':
            JSON.parse(sessionStorage.getItem("loginType"))===4?'工业互联网数据中台':
                '智慧能源平台'),
        loginType:1,
    };

    componentDidMount() {

        this.setState({loginType:JSON.parse(sessionStorage.getItem("loginType"))});


        // this.getDate()
    }

    getDate = () => setInterval(() => {
        let user = JSON.parse(sessionStorage.getItem("user"));
        // console.log('   let user = JSON.parse(sessionStorage.getItem("user"));');
        // console.log(user);
        // console.log('----------------------------------------------')
        if(user){
            if(user.orgName.indexOf("新奥") >= 0){
                $('#rLogo').attr('src',xinaoLogo)
            }
            this.setState({logoText:user.orgName+'智慧能源平台'})
        }
		this.setState({dates: new Date().toLocaleString()})
	},1000);

    // 退出账号
    outline = () => {


        var userInfo = JSON.parse(sessionStorage.getItem("user"));
        console.log(userInfo);
        // 退出参数
        var param = {
            userId:userInfo.id,         // 用户ID
            userName:userInfo.username, // 用户名
            account:userInfo.account,   // 账号
            phone:userInfo.phone,       // 手机号
            realName:userInfo.realName, // 真实姓名
            email:userInfo.email,       // 邮箱
            status:userInfo.status,     // 用户操作状态,0代表成功,1代表失败
            remark:null,                // 记录成功失败原因
        };

        //登出记录接口
        logout(param).then(
            response=>{
                console.log(response);
            }
        );





        sessionStorage.clear();
        localStorage.clear();
        this.props.history.push({
			pathname: '/'
		})
    };

    render() {

        const {dates} = this.state
        const {visible} = this.props
        let user = JSON.parse(sessionStorage.getItem("user"))


        return (
            <Header style={{backgroundColor: '#11193E',width: '100vw',height: '80px',margin: 0,paddingRight: 0,paddingLeft:'10px'}}>
                <div className={'xinaoLogo'} style={{
                    float: 'left',
                    height: '100%',
                    backgroundColor: '#11193E',
                    color: '#ffffff',
                    display: 'flex',
                    fontSize: '0rem',
                    alignItems: 'center',
                }}>
                     {/*<NavLink to="/"><img style={{height: '64px',marginTop:'18px'}} src={xinaoLogo} alt="新奥logo" /></NavLink> */}
                    {/*正常标题*/}
                    <span style={{fontSize: '24px', fontWeight: '600',marginLeft: '10px',marginTop:'10px'}}>
                        {this.state.logoText}
                    </span>
                    {/*新奥标题*/}
                    {/*<span style={{fontSize: '24px', fontWeight: '600',marginLeft: '10px',marginTop:'10px'}}>长沙新奥数据分析与管理系统</span>*/}
                </div>

                <div style={{color: '#ffffff',float: 'right',padding:'13px 10px',display:this.state.loginType===2?null:'none'}}>
                    <img id='rLogo' style={{width: '140px'}} src={hdstLogo} alt="汉德史特logo" />
                </div>
                <div style={{float: 'right', backgroundColor: '#11193E', color: '#ffffff',lineHeight:'90px',fontSize:'14px'}} className={'font_size09 topLink'}>
                    <span style={{lineHeight:'80px'}}><ClockCircleOutlined />&nbsp;{dates}</span>
                    <Menu
                        mode="horizontal"
                        style={{float: 'right', backgroundColor: '#11193E', color: '#ffffff',fontSize:'14px'}}
                    >
                        <SubMenu style={{color: '#ffffff'}} key="user" icon={<UserOutlined />} title={user?.realName}>
                            <Menu.Item key="personnal-center">
                                <NavLink to="/personalCenter"><UserOutlined />个人中心</NavLink>
                            </Menu.Item>
                            {(this.state.loginType===3)?
                                <Menu.Item key="data-panel">
                                    <NavLink to="/regionalInfo"><PieChartOutlined />数据面板</NavLink>
                                </Menu.Item>:null
                            }
                            <Menu.Item key="logout" onClick={this.outline}>
                                <PoweroffOutlined />退出
                            </Menu.Item>
                        </SubMenu>
                    </Menu>
                </div>
            </Header>
        )
    }
}
export default withRouter(LogoMenu)
