import ajax from './ajax'

// 根据用户名称查询客户
export const getPageCustomerName = data => ajax('/web/customerCtrl/getPageCustomerName', data, 'POST')

// 新增客户：开户
export const addCustomer = data => ajax('/web/customerCtrl/addCustomer', data, 'POST')

// 根据客户id分页查询计量点信息（模糊条件：用气地址）
export const getPageMpAddress = data => ajax('/web/customerCtrl/getPageMpAddress', data, 'POST')

// 客户页查询表具信息（模糊条件：表钢号）
export const getMetersByAreaIds = data => ajax('/web/customerCtrl/getMetersByAreaIds', data, 'POST')

// 根据客户id查询客户
export const getCustomerById = data => ajax('/web/customerCtrl/getCustomerById', data, 'GET')

export const getXX = data => ajax('/web/customerCtrl/getXX', data, 'POST')


export const openMp = data => ajax('/web/stateMachineCtrl/openMp', data, 'POST')

export const deleteMp = data => ajax('/web/mpCtrl/deleteMp', data, 'GET');

// 根据客户ID查询绑定的安装点信息
export const getLocationById = data => ajax('/web/newPoint/getLocationById', data, 'POST');
// 新增安装点
export const saveLocation = data => ajax('/web/newPoint/saveLocation', data, 'POST');

// 安装设备
export const installationDev = data => ajax('/web/newPoint/installationDev', data, 'POST');
// 根据安装点ID查询绑定的设备信息
export const getBoundDevicesById = data => ajax('/web/newPoint/getBoundDevicesById', data, 'POST');
// 注销设备
export const removeDev = data => ajax('/web/newPoint/removeLocation', data, 'GET');
// 修改安装点
export const updateInstallationPoint = data => ajax('/web/newPoint/updateInstallationPoint', data, 'POST');
// 设备拆卸
export const devDisassembly = data => ajax('/web/newPoint/devDisassembly', data, 'POST');

//查询用户的客户权限
export const queryCustomerPurview = (data) => ajax('/web/customerCtrl/queryCustomerByUserId', (data), 'GET')

//根据条件查询客户
export const queryCustomerByCondition = (data) => ajax('/web/customerCtrl/queryCustomerByCondition', (data), 'POST')

//删除用户的客户权限
export const removeRelationByUserId = (data) => ajax('/web/customerCtrl/removeRelationByUserId', (data), 'POST')

//给用户指定客户权限
export const appointCustomerPurview = (data) => ajax('/web/customerCtrl/appointCustomerPurview', (data), 'POST')



//解绑表具
export const disarmMeter = (data) => ajax('/web/stateMachineCtrl/disarmMeter', (data), 'POST');


//迁移客户计量点
export const migrateMeteringPointCustomers = (data) => ajax('/web/customerCtrl/migrateMeteringPointCustomers', (data), 'POST');

//注销客户
export const cancelCustomer = (data) => ajax('/web/customerCtrl/cancelCustomer', (data), 'GET');
//注销客户
export const cancelCustomerNew = (data) => ajax('/customermanage/customer/cancelCustomer', (data), 'GET');



//查询所有未绑定的设备
export const getUnboundValveAlarm = (data) => ajax('/web/valveInfo/getUnboundValveAlarm', (data), 'POST');
//安装设备
export const installationValveAlarm = (data) => ajax('/web/valveInfo/installationValveAlarm', (data), 'POST');
//根据安装点ID查询绑定的设备信息
export const getBoundValveAlarmById = (data) => ajax('/web/valveInfo/getBoundValveAlarmById', (data), 'POST');
// 阀井拆卸
export const valveAlarmDisassembly = data => ajax('/web/valveInfo/valveAlarmDisassembly', data, 'POST');

// 查询所有未绑定的设备
export const getUnboundDevices = data => ajax('/web/newPoint/getUnboundDevices', data, 'POST');
// 查询控制器下未绑定的设备
export const getUnboundDevicesByConId = data => ajax('/web/newPoint/getUnboundDevicesByConId', data, 'POST');

// 安装设备(新接口，老接口不用了)
export const newInstallationDev = data => ajax('/web/newPoint/newInstallationDev', data, 'POST');

// 新分页查询客户
export const newGetPageCustomerName = data => ajax('/customermanage/customer/newGetPageCustomerName', data, 'POST');


//  // 查询安全员列表
export const getSafetyOfficerByCustomerId = data => ajax('/customermanage/newCustomer/getSafetyOfficerByCustomerId', data, 'POST');

// 新增客户
export const addCustomerNew = data => ajax('/customermanage/newCustomer/addCustomer', data, 'POST');
// 修改客户
export const updateCustomerNew = data => ajax('/customermanage/newCustomer/updateCustomer', data, 'POST');

// 新增安全员信息
export const addSafetyOfficer = data => ajax('/customermanage/newCustomer/addSafetyOfficer', data, 'POST');
// 修改安全员信息
export const updateSafetyOfficer = data => ajax('/customermanage/newCustomer/updateSafetyOfficer', data, 'POST');
// 删除安全员信息
export const removeSafetyOfficer = data => ajax('/customermanage/newCustomer/removeSafetyOfficer', data, 'GET');

// 客户分配权级
export const configLevel = data => ajax('/customermanage/newCustomer/configLevel', data, 'POST');

// 根据子客户查询父客户
export const getCustomerByChild = data => ajax('/customermanage/newCustomer/getCustomerByChild', data, 'GET');
// 根据父客户查询子客户
export const getCustomerByParent = data => ajax('/customermanage/newCustomer/getCustomerByParent', data, 'POST');
// 客户关系解绑
export const customerUnbind = data => ajax('/customermanage/newCustomer/customerUnbind', data, 'GET');


// 添加管道数据
export const addPiPingDev = data => ajax('/gasdevice/piPingDev/addPiPingDev', data, 'POST');
// 修改管道数据
export const updatePiPingDev = data => ajax('/gasdevice/piPingDev/updatePiPingDev', data, 'POST');
// 删除管道数据
export const removePiPingDev = data => ajax('/gasdevice/piPingDev/removePiPingDev', data, 'GET');
// 根据区域ID查询管道信息
export const getInfoByAreaIds = data => ajax('/gasdevice/piPingDev/getInfoByAreaIds', data, 'GET');



// 查询工商和民用的用气量的数据
export const queryAccumulates = data => ajax('/web/mpCtrl/queryAccumulates', data, 'GET');
// 查询不同设备类型下的报警信息
export const pageAlarmInfo = data => ajax('/gasdevice/devPublic/pageAlarmInfo', data, 'POST');


