import React, { Component } from 'react'
import {Button, Layout, message} from "antd"
import { Route,Switch,Redirect } from "react-router-dom"
import LogoMenu from '../../components/common/toMenu/LogoMenu'
import Menus from '../../components/common/menus/Menus'

import {linkMenus} from '../../route/linkMenu'
import {components} from '../../route/linkMenu'
import {treeNodeAll,getTreeByNodeIds} from '../../services/area'
import PersonalCenter from '../../pages/personalCenter/PersonalCenter'
import { DoubleRightOutlined } from '@ant-design/icons';
import './home.less'
import '../../style/font/iconfont.css'
import {getdataTree,getDateByUserId} from "../../services/admin";

import HomeChatWindow from './HomeChatWindow'
export default class Client extends Component {

    state = {
        // 菜单栏参数
		linkMenus: {
			title: '用户管理',
			width: 15,
            backgroundColor: '#ffffff',
            // linkMenus: linkMenus,
			linkMenus: JSON.parse(sessionStorage.getItem("menuAuthories") === 'undefined' ? 'null' : sessionStorage.getItem("menuAuthories")),
			visible: false
		},
        // 菜单栏参数
		menus: {
			title: '区域管理',
			width: 15,
			backgroundColor: '#ffffff',
			visible: true,
			content: []
		},
		loginType:1,

		bosFunction:true// 对话窗开关
    };

	async componentDidMount(){
		this.setState({loginType:JSON.parse(sessionStorage.getItem("loginType"))});
		this.getData()
	}

	componentWillUnmount (){


		let {menus} = this.state;
		try{
			let menusCache = JSON.parse(localStorage.getItem('menus') ?? null) ?? this.getDefaultKey(menus.content?.[0]) ?? null;

			localStorage.setItem('areaName', menus.content?.[0]?.name);
			localStorage.setItem('menus', menusCache)
		}catch(e){
			sessionStorage.clear();
			localStorage.clear();
			this.props.history.push({
				pathname: '/'
			})
		}
	}

	getDefaultKey = data => {
		if(data?.children?.length > 0){
			this.getDefaultKey(data.children)
		}
		return data?.id
	};

	getData = () => {
		const dataAuthories = JSON.parse(sessionStorage.getItem("dataAuthories") === 'undefined' ? 'null' : sessionStorage.getItem("dataAuthories"))
		// treeNodeAll().then(
		// 	response => {
		// 		let {menus} = this.state
		// 		menus.content = JSON.parse(response.data?.data)
		// 		this.setState({menus})
		// 	}
		// )
		// getTreeByNodeIds(dataAuthories).then(
		// 	response => {
		// 		let {menus} = this.state;
		// 		menus.content = JSON.parse(response.data?.data ?? null)
		// 		this.setState({menus})
		// 	}
		// )
		getDateByUserId({userId:JSON.parse(sessionStorage.getItem("user"))?.id}).then(
				response => {
					if (response?.data) {
						let {menus} = this.state;
						menus.content = response?.data;
						this.setState({menus});
					} else {
						return message.info('获取不到数据');
					}
				}
		)
		// getdataTree().then(
		// 	response => {
		// 		if(response?.data?.data){
		// 			console.log(response?.data?.data);
		// 			let {menus} = this.state;
		// 			menus.content = response.data?.data;
		// 			this.setState({menus});
		// 		}else{
		// 			return message.info('获取不到数据');
		// 		}
		// 	}
		// )
	}

	showDrawer = () => {
		let {menus} = this.state;
		menus.visible = !menus.visible;
	  	this.setState({ menus });
	}

	onClose = () => {
		let {menus} = this.state
		menus.visible = false
	  	this.setState({ menus });
	};
	onTreeClose = () => {
		let {menus} = this.state;
		menus.visible = true;
	  	this.setState({ menus });
	};


    render() {

		const {visible} = this.state.menus;
		let menuAuthories = JSON.parse(sessionStorage.getItem("menuAuthories") === 'undefined' ? 'null' : sessionStorage.getItem("menuAuthories"))

		// // 移动功能
		// var dragItem = document.getElementById("draggable");
		// var active = false;
		// var currentX;
		// var currentY;
		// var initialX;
		// var initialY;
		// var xOffset = 0;
		// var yOffset = 0;
		// if(dragItem){
		// 	dragItem.addEventListener("mousedown", dragStart, false);
		// 	document.addEventListener("mouseup", dragEnd, false);
		// 	document.addEventListener("mousemove", drag, false);
		// }
		//
		//
		// function dragStart(e) {
		// 	initialX = e.clientX - xOffset;
		// 	initialY = e.clientY - yOffset;
		//
		// 	if (e.target === dragItem) {
		// 		active = true;
		// 	}
		// }
		//
		// function dragEnd(e) {
		// 	initialX = currentX;
		// 	initialY = currentY;
		//
		// 	active = false;
		// }
		//
		// function drag(e) {
		// 	if (active) {
		// 		e.preventDefault();
		//
		// 		currentX = e.clientX - initialX;
		// 		currentY = e.clientY - initialY;
		//
		// 		xOffset = currentX;
		// 		yOffset = currentY;
		//
		// 		setTranslate(currentX, currentY, dragItem);
		// 	}
		// }
		//
		// function setTranslate(xPos, yPos, el) {
		// 	el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
		// }


        return (
            <Layout className={'item_body_screen'} style={{ overflow: 'hidden'}}>
				{/*标头*/}
                <LogoMenu showDrawer={this.showDrawer} visible={visible}/>
                {/*内容*/}
                <Layout style={{height: '93.4vh'}}>
					{/*功能栏-左*/}
                    <Menus
						menus={this.state.menus}
						linkMenus={this.state.linkMenus}
						onClose={this.onClose}
						showDrawer={this.showDrawer}
					/>

					{/*<div id={'homeAmplify'}>*/}
					{/*	<div className={'amplify_div'}>*/}
					{/*		<DoubleRightOutlined />*/}
					{/*	</div>*/}
					{/*</div>*/}



					{/*/!*客服按钮功能*!/*/}
					{/*<div id={'homeDialogButton'}>*/}
					{/*	/!*按钮*!/*/}
					{/*	<div className={'homeDialogButton_div_1'}>*/}
					{/*		<div className={'homeDialogButton_div_img'} onClick={()=>{this.HomeChatWindow.setHomeChatWindow();}}/>*/}
					{/*	</div>*/}
					{/*</div>*/}


					{/*客服对话框-右*/}
					<HomeChatWindow ref={e => this.HomeChatWindow = e}/>

					{/*功能页面*/}
					<div className={'item_body_number'} style={{position: 'relative'}} onMouseMove={this.onTreeClose}>
						<Layout style={{width: '99%',height: '90vh',margin: '0 0.5%'}}>


							{/*<div id="draggable" style={{*/}
							{/*	zIndex: '9999',*/}
							{/*	width: '200px',*/}
							{/*	height: '50px',*/}
							{/*	background: 'skyblue',*/}
							{/*	position: 'absolute',*/}
							{/*	cursor: 'move'*/}
							{/*}}>*/}
							{/*	Drag me around*/}
							{/*</div>*/}


							<Switch>
								{
									menuAuthories?.map(data => {
										return data.children?.length > 0 ?  data.children?.map(value => {
											return value.children?.length > 0 ?  value.children?.map(v => {
												return <Route
													exact path={v.url}
													key={v.url}
													component={components[v.components]}
												/>
											})
												: <Route

													exact path={value.url}
														 key={value.url}
														 component={components[value.components]}
												/>
										})
											: <Route
													 exact path={data.url}
													 key={data.url}
													 component={components[data.components]}
											/>
									})
								}
								{/* {
									userName ? linkMenus.map(data => {
										return data.children.length > 0 ?  data.children.map(value => {
											return value.children.length > 0 ?  value.children.map(v => {
												return <Route exact path={data.url+v.url} component={v.components} />
											}) : <Route exact path={data.url+value.url} component={value.components} />
										}) : <Route exact path={data.url} component={data.components} />
									}) : <Redirect from={'/'} to={'/login'} />
								} */}

								<Route exact from={'/personalCenter'} to={'/personalCenter'} component={PersonalCenter}/>
								<Redirect exact from={'/customer'} to={'/customer/clientBusiness'} />
								<Redirect exact from={'/'} to={'/login'} />
								{/* <Redirect from={'/*'} to={'/error'} /> */}
							</Switch>
						</Layout>
					</div>
                </Layout>
				{/*低部*/}
				<div style={{width: '99%',margin:'0 0.5%', height: '2.4vh',textAlign: 'center',color: '#d6d1d1',backgroundColor: '#ffffff',position: 'absolute',bottom: 0}}>
							<i style={{color: '#d6d1d1', marginRight: '1vw'}}>湖南汉德史特仪表有限公司版权所有</i>
							备案号：<a style={{color: '#d6d1d1'}} href="https://beian.miit.gov.cn">湘ICP备2021021424号-1</a>
				</div>




            </Layout>
        )
    }
}
