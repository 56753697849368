import ajax from './ajax'

//创建组织机构
export const addOrganizationTree = data => ajax('/newAuth/admin/organization/create', data, 'POST');

//更新组织机构-旧
export const updateOrganizationTree = data => ajax('/newAuth/admin/organization/update', data, 'PUT');
//更新组织机构-新
export const updateNew = data => ajax('/newAuth/admin/organization/updateNew', data, 'POST');


//删除组织机构
export const deleteOrganizationTree = data => ajax('/newAuth/admin/organization/delete', data, 'POST');

// 获取组织树-旧
export const getOrganizationTree = data => ajax('/newAuth/admin/organization/tree', null, 'GET');


// 获取组织树-新
export const getNewTree = data => ajax('/newAuth/admin/organization/getNewTree', data, 'GET');






// 获取APP组织树
export const getAppOrganizationTree = data => ajax('/newAuth/adminApp/organizationManagement/tree', null, 'GET')

//创建APP组织机构
export const addAppOrganizationTree = data => ajax('/newAuth/adminApp/organizationManagement/createOrganization', data, 'POST')

//更新APP组织机构
export const updateAppOrganizationTree = data => ajax('/newAuth/adminApp/organizationManagement/updateOrganization', data, 'POST')


// 根据公司ID查询公司信息
export const getCompanyInfoInCompanyID = data => ajax('/newAuth/adminApp/organizationManagement/getCompanyInfoInCompanyID', data, 'GET')


// 获取公司类型列表
export const getOrgTypeInPage = data => ajax('/newAuth/adminApp/orgTypeManagement/getOrgTypeInPage', data, 'POST')
// 获取公司类型
export const getOrgType = data => ajax('/newAuth/adminApp/orgTypeManagement/getOrgType', data, 'GET')
// 新增公司类型列表
export const createOrgType = data => ajax('/newAuth/adminApp/orgTypeManagement/createOrgType', data, 'GET')
// 修改公司类型列表
export const updateOrgType = data => ajax('/newAuth/adminApp/orgTypeManagement/updateOrgType', data, 'POST')
// 删除公司类型列表
export const removeOrgType = data => ajax('/newAuth/adminApp/orgTypeManagement/removeOrgType', data, 'GET')




//根据组织ID查询APP用户基本信息
export const listAppUserByOrganizationId = (data) => ajax('/newAuth/adminApp/userManagement/listAppUserByOrganizationId', (data), 'POST')

//添加APP用户
export const addAccount = data => ajax('/newAuth/adminApp/userManagement/addAccount', data, 'POST')

//根据组织ID查询APP未审批用户基本信息
export const fuzzySearchForUnapprovedUsers = (data) => ajax('/newAuth/adminApp/userManagement/fuzzySearchForUnapprovedUsers', (data), 'POST')


//查询所有APP功能
export const getFunctionList = data => ajax('/newAuth/adminApp/functionManagement/getFunctionList', data, 'GET')


//根据职位ID查询职位功能
export const getFunctionByPositionID = data => ajax('/newAuth/adminApp/functionManagement/getFunctionByPositionID', data, 'GET')

//APP功能授权
export const updateAuth = (data) => ajax('/newAuth/adminApp/positionManagement/updateAuth', (data), 'POST')















//删除APP组织机构
export const deleteAppOrganizationTree = data => ajax('/newAuth/adminApp/organizationManagement/deleteOrganization', data, 'GET')

//根据App组织ID查询职位名称
export const listAppPositionsByOrganizationId = (data) => ajax('/newAuth/adminApp/positionManagement/listPositionsByOrganizationId', (data), 'GET')

//添加职位
export const addAppPosition = data => ajax('/newAuth/adminApp/positionManagement/createPosition', data, 'POST')

//修改职位
export const updateAppPosition = data => ajax('/newAuth/adminApp/positionManagement/updatePosition', data, 'POST')

//删除职位
export const deleteAppPosition = data => ajax('/newAuth/adminApp/positionManagement/removePosition', data, 'GET')












//添加职位
export const addPosition = data => ajax('/newAuth/admin/position/create', data, 'POST')

//根据职位序号查询职位
export const getPositionById = data => ajax('/newAuth/admin/position/query', data, 'POST')

//修改职位
export const updatePosition = data => ajax('/newAuth/admin/position/update', data, 'PUT')

//删除职位
export const deletePosition = data => ajax('/newAuth/admin/position/delete', data, 'GET')

//查询用户
export const queryUser = data => ajax('/newAuth/admin/user/query', data, 'POST')

//模糊查询用户
export const fuzzyQueryUser = data => ajax('/newAuth/admin/user/fuzzyQueryUser', data, 'POST')

//添加用户
export const insertUser = data => ajax('/newAuth/admin/user/add', data, 'POST')

//根据组织ID查询职位名称
export const listPositionsByOrganizationId = (data) => ajax('/newAuth/admin/position/listPositionsByOrganizationId', (data), 'GET')






//根据组织ID查询用户基本信息
export const listUserByOrganizationId = (data) => ajax('/newAuth/admin/user/listUserByOrganizationId', (data), 'POST')


//修改用户信息
export const updateUser = (data) => ajax('/newAuth/admin/user/update', (data), 'PUT')

//删除用户
export const deleteUser = (data) => ajax('/newAuth/admin/user/delete', (data), 'POST')

//注销APP用户
export const logOffUser = (data) => ajax('/newAuth/adminApp/userManagement/logOffUser', (data), 'GET')



//更新用户状态
export const updateUserStatus = (data) => ajax('/newAuth/admin/user/updateUserStatus', (data), 'POST')


//删除用户
export const updateState = (data) => ajax('/newAuth/adminApp/userManagement/updateState', (data), 'POST')


//获取菜单树
export const getMenuTree = (data) => ajax('/newAuth/admin/menu/getMenuTree', (data), 'GET')

//根据菜单的节点id查询资源
export const getResourcesByNodeId = (data) => ajax('/newAuth/resource/queryResourcesByNodeId', (data), 'GET')

//获取权限树
export const getdataTree = (data) => ajax('/newAuth/admin/data/tree', (data), 'GET')

//新增数据权限
// export const insertData = (data) => ajax('/admin/data/save', (data), 'POST')
export const insertMenu = (data) => ajax('/newAuth/admin/menu/addMenu', (data), 'POST')

//修改数据权限
// export const updateData = (data) => ajax('/admin/data/update', (data), 'POST')
export const updateMenu = (data) => ajax('/newAuth/admin/menu/update', (data), 'GET')

//删除数据权限
// export const deleteData = (data) => ajax('/admin/data/delete', (data), 'POST')
export const deleteMenu = (data) => ajax('/newAuth/admin/menu/delete', (data), 'GET')

//给职位新增或修改权限
export const addOrUpdateAuth = (data) => ajax('/newAuth/admin/position/addOrUpdateAuth', (data), 'POST')

//查询职位信息
export const queryPositionInfo = (data) => ajax('/newAuth/admin/position/query', (data), 'GET')

//查询用户名是否已经存在,返回true代表已经存在
export const queryUsernameExist = (data) => ajax('/newAuth/admin/user/queryUsernameExist', (data), 'GET')


//修改用户职位
export const updatePositionByUserId = (data) => ajax('/newAuth/adminApp/positionManagement/updatePositionByUserId', (data), 'POST')
//用户审批
export const appApproval = (data) => ajax('/newAuth/adminApp/userManagement/appApproval', (data), 'POST')
//查询申请的检定人员和工程人员
export const getApprover = (data) => ajax('/newAuth/adminApp/user/getApprover', (data), 'POST')
//审批工程人员和检定人员
export const examine = (data) => ajax('/newAuth/adminApp/user/examine', (data), 'GET')
//根据ID查询个人信息
export const getUserById = (data) => ajax('/newAuth/admin/user/getUserById', (data), 'GET')
//修改密码
export const updatePassword = (data) => ajax('/newAuth/admin/user/updatePassword', (data), 'POST')
// 新增数据权限
export const save = data => ajax('/newAuth/admin/data/save', data, 'POST');
// 修改数据权限
export const update = data => ajax('/newAuth/admin/data/update', data, 'POST');
// 删除数据权限,不支持根据父节点删除下面的所有字节点,前端做限制只能一个个删除
export const deleteTree = data => ajax('/newAuth/admin/data/delete', data, 'GET');
//根据用户查询数据权限
export const getDateByUserId = (data) => ajax('/newAuth/admin/data/getDateByUserId', (data), 'GET');

//通过区域id查询管道数据
export const queryPipingByAreaId = (data) => ajax('/web/pipingWeb/queryPipingByAreaId', (data), 'GET');

//获取数据项
export const getItem = (data) => ajax('/measurepoint/earlyWarning/getItem', (data), 'GET');


// 添加客服配置
export const addCustomerService = data => ajax('/meterimport/userType/addCustomerService', data, 'GET');
// 根据用户ID查询是否为客服
export const getHomeInfoByOrgIds = data => ajax('/meterimport/userType/getHomeInfoByOrgId', data, 'GET');
// 根据用户Id删除客服配置
export const removeByUserId = data => ajax('/meterimport/userType/removeByUserId', data, 'GET');


