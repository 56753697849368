import React, { Component } from 'react'
import { NavLink } from "react-router-dom"
import {
    Layout,
    Modal,
    Radio,
    Form,
    DatePicker,
    Button,
    Select,
    Input,
    Table,
    message,
    Spin,
    Divider,
    Space,
    Icon
} from 'antd'
import {TYPE_NAME} from '../../../components/Const'
import {funTraceInfo} from '../../../services/common'
import TableComponents from '../../../components/common/tables/TableComponents'
import TableComponentsNew from '../../../components/common/tables/TableComponentsNew'
import {
    getPageCustomerName,
    cancelCustomer,
    getPageMpAddress,
    getLocationById,
    newGetPageCustomerName
} from '../../../services/customer'
import {
    addInspectionDev,
    addPoPatrolScheme,
    getInspectionDevInPatrolId,
    queryList,
    querySecurityAssessment, removeInspectionDev, removePatrolSchemeInId, updatePoPatrolScheme
} from "../../../services/meter";
import moment from "moment";
import {getAllClassAndTemp} from "../../../services/equipment";
import { StepBackwardOutlined ,StepForwardOutlined,PlusOutlined} from '@ant-design/icons';
import {pageValveInfo} from "../../../services/qrCode";
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;
// 巡检计划
export default class inspection extends Component {

    state = {

        InputData:'',


        isModalOpen_1:false,// 弹窗-1
        isModalOpen_2:false,// 弹窗-2
        isModalName_1:'新增',
        isModalName_2:'添加',

        noMsgJuder_1:false,// 加载控制

        initialValues_1:{id:1},// 弹窗-1-表单数据
        initialValues_2:{},// 弹窗-2-表单数据
        initialValues_3:{},// 弹窗-2-表单内选取设备
        // 设备列表-1-设备类型
        optionDataS_1:[
            // { value: '10196', label: '报警控制器' },
            // { value: '10168', label: '报警探测器' },
            // { value: '10200', label: '阀井' },
            // { value: '10202', label: '家用报警' },
            // { value: '10210', label: '管钉' },
            // { value: '10171', label: '压力表' },
            // { value: '10209', label: '监控' },
            // { value: '10211', label: '调压柜' },
        ],
        // 设备列表-2-设备编号
        optionDataS_2:[],
        // 设备列表-2-查询参数
        optionPage: {
            devType:null,
            page: 1,
            size:5,
            AllPage:0,
        },
        // 弹窗-设备表格
        tableData_1_head:[
            {title: '设备编号', dataIndex: 'devId', key: 'devId'},
            {title: '设备类型', dataIndex: 'devType', key: 'devType',
                render: (text, rowData,index) => {
                var name = null;
                if(text){
                    this.state.optionDataS_1.map(one=>{
                        if(text===one.value){
                            name=one.label.toString();
                        }});
                }
                    return<>
                        <div style={{width:'100%'}}>{name}</div>
                    </>
                }
            },
            {title: '操作', dataIndex: 'edit',width:'200px', align:'center',
                render: (text, rowData,index) => {
                    return<>
                        <div style={{width:'100%'}}>
                            <div  title={'删除'} style={{cursor: 'pointer',width:'60px',float:'left'}}>
                                <u style={{color:'red'}} onClick={()=>{this.deleteDev(rowData.id);}}>删除</u>
                            </div>
                        </div>
                    </>
                }

            }
        ],
        tableData_1_data:[],
        tableData_1_loading:false,



        // 表格参数
        tableParamenter: {
            manageName: "巡检计划",
            // 是否绑定左侧区域
            bindArea: true,
            // 表格行是否可选
            rowSelection: false,
            // 获取表格参数数据
            localStorageName: 'inspection',
            //下拉框长度
            selectWidth: '7.5vw',
            // 表头：变量和变量解释
            fileName: '居民安全自检管理',
            header: {
                planNumber: '计划编号',
                name: '计划名称',
                startTimeOfPlan: '开始时间',
                endTimeOfPlan: '结束时间',
                state: '计划状态',
                area: '巡检区域',
                inspectorDev: '巡检设备数量',
                abnormalDev: '异常设备',
                edit: '操作',
            },

            // 条件选择器参数
            conditionsParamenter: {
                // 条件
                parameter: [
                    {
                        type: TYPE_NAME.RANGE_PICKER, variableName: 'time', str: '计划时间',
                        initData: JSON.parse(localStorage.getItem('inspection'))?.name
                    },
                    {
                        type: TYPE_NAME.NAME, variableName: 'planNumber', str: '计划编号',
                        initData: JSON.parse(localStorage.getItem('inspection'))?.addressInfo
                    },
                    {
                        type: TYPE_NAME.NAME, variableName: 'area', str: '巡查区域',
                        initData: JSON.parse(localStorage.getItem('inspection'))?.addressInfo
                    },
                ],
                onchange:()=>{console.log('111111111')}
            },
            params: {

                page: 1,
                size:10,
            }
        },




    };
    componentWillMount() {

        // 查询设备类型
        getAllClassAndTemp().then(e=>{
            var datas=[];
            e?.data?.data.map(one=>{datas.push({key:one.devClassId,value:one.devClassId,label:one.devClassName})});
            this.setState({optionDataS_1:datas})
        });


    }

    // 刷新设备列表
    pageValveInfo=(steelNum,devType,page)=>{
        var optionPage = this.state.optionPage;
        var param={
            steelNum:steelNum??null,
            devType:devType??null,
            size:optionPage.size,
            page:page??1
        };
        var optionDataS2 = [...this.state.optionDataS_2];
        optionDataS2=[];
        pageValveInfo(param).then(
            response=>{
                if(response?.data?.data){
                    optionDataS2=response?.data?.data;
                    optionPage.AllPage=Math.ceil(response?.data?.pageInfo.total/optionPage.size);
                    console.log(optionPage);
                    this.setState({optionPage:optionPage});
                }
                this.setState({optionDataS_2:optionDataS2});
            }
        )
    };

    setTableValue = (value,text,rowData) => {
        switch (value) {
            case 'state':
                return (
                    <div>
                        <span>{text===0?'未开始':text===2?'进行中':text===3?'已完成':null}</span>
                    </div>
                );

            case 'edit':

                return (
                    <div>
                        <div style={{float: 'left'}}>
                            <div className={'table_overflow'} title={text}>
                                <a onClick={async ()=>{
                                    // 选取表单的数据
                                    var data = Object.assign(rowData, {dateTime:[]});
                                    data.dateTime=rowData?.startTimeOfPlan? [moment(rowData?.startTimeOfPlan),moment(rowData?.endTimeOfPlan)] : [];
                                    data.inspector=rowData?.inspector?JSON.parse(rowData?.inspector):null;
                                    // 表单复制和打开窗口
                                    await this.setState({initialValues_1:data,isModalOpen_1:true});
                                    this.getInspectionDevInPatrolId();  // 检查有无添加设备
                                    await this.form1.resetFields();     // 刷新表单展示
                                }}>编辑</a>
                            </div>
                        </div>
                    </div>
                );
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };

    // 新增巡检计划
    addPoPatrolScheme=async()=> {
        var formData = await this.form1.validateFields();
        console.log(formData);
        if (!formData) {return false;}
        var param = {
            "area": formData.area??null,    // 巡检区域
            "createTime": null,             // 创建时间
            "description": formData.description??null,  // 计划描述
            "startTimeOfPlan": formData.dateTime ?moment(formData.dateTime[0]._d) : null, // 计划开始时间
            "endTimeOfPlan": formData.dateTime ?moment(formData.dateTime[1]._d) : null,   // 计划结束时间
            "id": null,                    // id
            "inspector": formData.inspector?JSON.stringify(formData.inspector):null ,  // 巡检人
            "name": formData.name??null,            // 计划名称
            "planNumber": null,                     // 计划编号
            "state": 0,                             // 计划状态(0-未开始/1-进行中/2-已完成)
            "updateTime": null                      // 修改时间
        };
        this.setState({noMsgJuder_1:true});
        addPoPatrolScheme(param).then(
            response=>{
                if(response?.data?.data){
                    message.info('提交成功');
                    this.setState({noMsgJuder_1:false,isModalOpen_1:false});
                    this.table.refreshTable();
                }else{
                    message.info('提交失败')
                }
            }
        )
    };
    // 修改巡检计划
    updatePoPatrolScheme = async () => {
        var formData = await this.form1.validateFields();
        if (!formData) {return false;}
        var initialValues1 = this.state.initialValues_1;// 选取的巡检计划

        var param = {
            "area": formData.area??null,    // 巡检区域
            "createTime": null,             // 创建时间
            "description": formData.description??null,  // 计划描述
            "startTimeOfPlan": formData.dateTime ?moment(formData.dateTime[0]._d) : null, // 计划开始时间
            "endTimeOfPlan": formData.dateTime ?moment(formData.dateTime[1]._d) : null,   // 计划结束时间
            "id": initialValues1?.id??null,                 // id
            "inspector": formData.inspector?JSON.stringify(formData.inspector):null ,  // 巡检人
            "name": formData.name??null,                    // 计划名称
            "planNumber": initialValues1?.planNumber??null, // 计划编号
            "state": 0,                                     // 计划状态(0-未开始/1-进行中/2-已完成)
            "updateTime": null                              // 修改时间
        };
        this.setState({noMsgJuder_1:true});

        updatePoPatrolScheme(param).then(
            response=>{
                if(response?.data?.data){
                    message.info('提交成功');
                    this.setState({isModalOpen_1:false});
                    this.table.refreshTable();
                }else{
                    message.info('提交失败')
                }
                this.setState({noMsgJuder_1:false});
            }
        )
    };

    // 根据巡检计划ID删除巡检计划
    removePatrolSchemeInId=(id)=>{

        removePatrolSchemeInId({id}).then(
            response=>{
                if(!response?.data?.data){return message.info('删除失败');}
                this.getInspectionDevInPatrolId();
                this.setState({tableData_1_loading: false});
            }
        )

    };


    // 根据巡检计划ID查询巡检设备
    getInspectionDevInPatrolId=()=>{
        var initialValues1 = this.state.initialValues_1;
        if(!initialValues1){return false;}

        getInspectionDevInPatrolId({patrolId:initialValues1.id}).then(
            response=>{
                console.log(response);
                if(response?.data?.data){
                    console.log('response?.data?.data');
                    this.setState({tableData_1_data:response?.data?.data});
                }else{
                    this.setState({tableData_1_data:[]});
                }
            }
        )
    };



    // 新增巡检设备
    addInspectionDev = async () =>{
        var data = await this.form2.validateFields();
        if(!data){return false;}
        var initialValues1 = this.state.initialValues_1;// 选取的巡检计划
        var initialValues3 = this.state.initialValues_3;// 内框内选取的设备
        this.setState({isModalOpen_2:false,tableData_1_loading: true});
        var optionPage = this.state.optionPage;
        var param={
            "createTime": null,   // 创建时间
            "devId": initialValues3?initialValues3.devId:data.devId,    // device服务主键id
            "devType": optionPage?.devType??null,                // 设备类型
            "id": null,                                 // Id
            "patrolSchemeId": initialValues1.id,        // 巡检计划Id
            "source": initialValues3?'系统内':'系统外',  // 来源(系统内/系统外)
            "updateTime": null                          // 修改时间
        };
        addInspectionDev(param).then(
            response=>{
                this.getInspectionDevInPatrolId();
                this.setState({tableData_1_loading: false});
            }
        );
    };



    // 删除设备
    deleteDev=(id)=>{
        if(!id){return false;}
        this.setState({tableData_1_loading: true});
        removeInspectionDev({id}).then(
            response=>{
                if(!response?.data?.data){return message.info('删除失败');}
                this.getInspectionDevInPatrolId();
                this.setState({tableData_1_loading: false});
            }
        );


    };

    render() {
        const {InputData,tableData_1_data,isModalOpen_1,isModalOpen_2,isModalName_1,isModalName_2,initialValues_1,initialValues_2,optionDataS_1,optionDataS_2,optionPage} = this.state;


        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <TableComponents
                    id={'customerId'}
                    paramenter={this.state.tableParamenter}
                    setData={queryList}
                    setTableValue={this.setTableValue}

                    ref={e => this.table = e}
                >
                    <Radio.Group defaultValue="addNew" buttonStyle="solid">
                        <Radio.Button value="addNew" onClick={async ()=>{
                            await this.setState({isModalOpen_1:true,initialValues_1:{}});
                            await this.form1.resetFields();
                        }}>新增</Radio.Button>
                    </Radio.Group>
                </TableComponents>

                {/*弹窗-1*/}
                <Modal
                    title={isModalName_1}
                    okText="确定"
                    open={isModalOpen_1}
                    onOk={()=>{
                        this.form1.resetFields();
                        this.setState({isModalOpen_1:false});
                    }}
                    onCancel={()=>{
                        this.form1.resetFields();
                        this.setState({isModalOpen_1:false})}}
                    width={1000}
                    centered
                    footer={[

                        <Button type="primary"  htmlType="submit"
                                onClick={()=>{
                                    if(initialValues_1?.id){
                                        this.updatePoPatrolScheme();// 修改巡检计划
                                    }else{
                                        this.addPoPatrolScheme();   // 新增巡检计划
                                    }


                                }}
                        >
                            保存
                        </Button>
                    ]}
                >
                    {/*等待页面*/}
                    <div>
                        <div style={{
                        	display:this.state.noMsgJuder_1? null:'none',
                            position: 'absolute',
                            top:'0',
                            paddingTop:'10%',
                        	width:'96%',
                        	height:'100%',
                        	fontSize:'30px',
                        	textAlign:'center',
                        	overflow:'auto',
                        	color:'#797979',
                        	zIndex:'2',
                            backgroundColor: 'rgba(245,245,245,0.5)'
                        }}>
                        	<Spin />等待中
                        </div>

                        {/*表单---------------------------------------------------------------------------------------*/}
                        <div>
                            <div style={{width:'100%',height:'50px'}}>
                                <span style={{fontSize:'18px',fontWeight:'bold'}}>基本信息</span>
                                <div style={{width:'100%',height:'1px',backgroundColor:'#f1f1f1'}}/>
                            </div>
                            <div>
                                <Form
                                    ref={e => this.form1 = e}
                                    labelCol={{ span: 4 }}
                                    wrapperCol={{ span: 15 }}
                                    layout="horizontal"
                                    initialValues={initialValues_1}
                                    style={{ maxWidth: 600 }}
                                    onFinish={(value)=>{console.log( value)}}
                                    onFinishFailed={(value)=>{console.log(2)}}

                                >
                                    <Form.Item label="计划名称" name={'name'} rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="计划时间" name={'dateTime'} rules={[{ required: true }]}>
                                        <RangePicker />
                                    </Form.Item>
                                    <Form.Item label="巡查区域" name={'area'} rules={[{ required: true }]}>
                                        <TextArea rows={3} />
                                    </Form.Item>
                                    <Form.Item label="计划描述" name={'description'}>
                                        <TextArea rows={3} />
                                    </Form.Item>
                                    <Form.Item label="巡检人" name={'inspector'} rules={[{ required: true }]}>
                                        <Select
                                            mode="tags"
                                            style={{ width: '100%' }}
                                            onChange={(text)=>{console.log(text)}}
                                            tokenSeparators={[',']}
                                        />
                                    </Form.Item>
                                    {/*<Form.Item label={null}>*/}
                                    {/*    <div style={{float:'right'}}>*/}
                                    {/*        <Button type="primary" htmlType="submit">*/}
                                    {/*            Submit*/}
                                    {/*        </Button>*/}
                                    {/*    </div>*/}
                                    {/*</Form.Item>*/}
                                </Form>
                            </div>
                        </div>
                        {/*表格---------------------------------------------------------------------------------------*/}
                        <div
                            style={{display:initialValues_1.id? null :'none'}}
                        >
                            {/*标题*/}
                            <div style={{width:'100%',height:'50px'}}>
                                <span style={{fontSize:'18px',fontWeight:'bold'}}>巡查设备</span>
                                <div style={{width:'100%',height:'1px',backgroundColor:'#f1f1f1'}}/>
                            </div>
                            {/*添加设备按钮*/}
                            <div style={{width:'100%',height:'40px'}}>
                                <div style={{float:'right'}}>
                                    <Button type="primary" htmlType="submit" onClick={()=>{this.setState({isModalOpen_2:true})}}>添加设备</Button>
                                </div>
                            </div>
                            {/*表格*/}
                            <div>
                                <Table
                                    columns={this.state.tableData_1_head}
                                    dataSource={tableData_1_data}
                                    bordered
                                    loading={this.state.tableData_1_loading}
                                    pagination = {{
                                        showSizeChanger:true,
                                        showQuickJumper: true,
                                        total:this.state.tableData_1_data.length,
                                        pageSize:4,
                                        showTotal: (count = tableData_1_data.length)=>{
                                            return '共' + tableData_1_data.length+'条数据'
                                        },
                                        defaultCurrent: 1,
                                        pageSizeOptions: ['4']
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {/*内部弹窗-设备*/}
                    <Modal
                        title={isModalName_2}
                        okText="确定"
                        open={isModalOpen_2}
                        onOk={()=>{
                            this.addInspectionDev();

                        }}
                        onCancel={()=>{
                            this.form2.resetFields();   // 清空表2数据
                            this.setState({isModalOpen_2:false,initialValues_2:{}});
                        }}
                        width={600}
                        centered
                    >
                        <div style={{width:'100%'}}>
                            <div style={{paddingLeft:'30px'}}>
                                <Form
                                    ref={e => this.form2 = e}
                                    labelCol={{ span: 5 }}
                                    wrapperCol={{ span: 14 }}
                                    layout="horizontal"
                                    initialValues={initialValues_2}
                                    style={{ maxWidth: 600 }}
                                    onFinish={(value)=>{console.log( value)}}
                                    onFinishFailed={(value)=>{console.log(2)}}

                                >
                                    <Form.Item name="devType" label="设备类型" rules={[{ required: true }]}>
                                        <Select allowClear
                                                options={optionDataS_1}
                                                onChange={(a,b)=>{
                                                    optionPage.devType=a;
                                                    optionPage.page=1;
                                                    optionPage.AllPage=0;
                                                    this.pageValveInfo(null,a,1);
                                                }}
                                        />
                                    </Form.Item>

                                    <Form.Item label="设备编号" name={'devId'} rules={[{ required: true }]}>
                                        <Select
                                            fieldNames={{ label: 'devId', value: 'devId', key:'id' }}
                                            options={optionDataS_2}
                                            onChange={(a,b)=>{this.setState({initialValues_3:b})}}

                                            dropdownRender={(menu) => (
                                                <>
                                                    {menu}
                                                    <Divider style={{ margin: '8px 0' }} />
                                                    <Space style={{ padding: '0 8px 4px' }}>
                                                        <div>
                                                            <Input placeholder={'手输编号'} style={{width:'120px'}}
                                                                   value={InputData}
                                                                   onChange={(text)=>{
                                                                       this.setState({
                                                                           initialValues_3:null,
                                                                           InputData:text.target.value,
                                                                       });

                                                                   }}
                                                            />
                                                            &nbsp;
                                                            <a type="text"
                                                               onClick={()=>{
                                                                   this.form2.setFieldsValue({['devId']:InputData});
                                                               }}>
                                                                添加
                                                            </a>

                                                            <span>
                                                                  &nbsp;&nbsp;总页数&nbsp;{optionPage.AllPage}&nbsp;
                                                            </span>
                                                            <StepBackwardOutlined
                                                                style={{
                                                                    color:optionPage.page<=1?'#ccc':'#5496d9',
                                                                    padding: '0 3px 4px',
                                                                    cursor:'pointer'}}
                                                                onClick={()=>{
                                                                    if(optionPage.page<=1){return false;}
                                                                    optionPage.page=optionPage.page-1;
                                                                    this.pageValveInfo(null,optionPage.devType,optionPage.page);
                                                                }}
                                                            />
                                                            <span>{optionPage.page}</span>
                                                            <StepForwardOutlined
                                                                style={{
                                                                    color:optionPage.page>=optionPage.AllPage?'#ccc':'#5496d9',
                                                                    padding: '0 3px 4px' ,
                                                                    cursor:'pointer'}}
                                                                onClick={()=>{
                                                                    if(optionPage.page>=optionPage.AllPage){return false;}
                                                                    optionPage.page=optionPage.page+1;
                                                                    this.pageValveInfo(null,optionPage.devType,optionPage.page);
                                                                }}
                                                            />
                                                        </div>
                                                    </Space>
                                                </>
                                            )}
                                        />
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </Modal>
                </Modal>
            </Layout>
        )
    }
}
