import React, { Component } from 'react'
import { NavLink } from "react-router-dom"
import {Layout, Radio, message, Tag, Popconfirm} from 'antd'
import {TYPE_NAME} from '../../../components/Const'
import TableComponents from '../../../components/common/tables/TableComponents'
import {
    cancelCustomer,
    getPageMpAddress,
    getLocationById,
    newGetPageCustomerName,
    cancelCustomerNew
} from '../../../services/customer'
import {querySecurityAssessment} from "../../../services/meter";

// 居民客户信息管理
export default class resident extends Component {

    state = {
        // 表格参数
        tableParamenter: {
            manageName: "居民客户信息管理",
            // 是否绑定左侧区域
            bindArea: true,
            // 表格行是否可选
            rowSelection: true,
            // // 获取表格参数数据
            localStorageName: 'ClientIndustry',
            //下拉框长度
            selectWidth: '7.5vw',
            // 表头：变量和变量解释
            fileName: '客户关系管理',
            exportUrl: "/web/customerExportCtrl/exportCustomer",

            header: {
                id: '客户编号',
                name: '客户名称',
                type: '客户类型',
                principal: '联系人',
                principalPhone: '联系电话',
                detailAddress: '详细地址',
                createTime:'创建时间',
                // securityCheckStatus: '安检状态',
                // gasApplianceStatus: '燃气具状态',
                edit: '操作',
            },

            // 条件选择器参数
            conditionsParamenter: {
                // 条件
                parameter: [
                    {
                        type: TYPE_NAME.NAME,
                        variableName: 'customerName',
                        str: '客户名称',
                        initData: JSON.parse(localStorage.getItem('ClientIndustry'))?.name
                    },
                    {
                        type: TYPE_NAME.NAME,
                        variableName: 'businessCooperationNum',
                        str: '业务合作号',
                        initData: JSON.parse(localStorage.getItem('ClientIndustry'))?.businessCooperationNum
                    },
                ]
            },
            params:{
                searchType:'PERSON',
                page:1,
                size:13
            }
        },
        href:"http://localhost:3000"
    };
    componentWillMount() {
        var href = window.location.href;
        var port = window.location.port;
        this.setState({
            href:href.substr(0,href.indexOf(port))+port

        });
    }

    // 注销客户
    cancelCustomer=(id)=>{
        if(!id){return message.info('请选择客户');}
        var param = {
            "page": 1,
            "size": 10,
            "address": "",
            "customerId": id
        };
        // 验证计量点
        getPageMpAddress(param).then(
            response=>{
                // return message.info('该客户存在计量点或安装点，无法进行注销');
                console.log(response);
                if(response?.data?.data){
                    console.log(response);
                    return message.info('该客户存在计量点，无法进行注销');
                }else{
                    var locatioParam = {customerId:id, page:1, size:10,locationType:'客户'};
                    // 验证安装点
                    getLocationById(locatioParam).then(
                        response1=>{
                            console.log(response1);
                            if(response1?.data?.data){
                                console.log(response1);
                                return message.info('该客户存在安装点，无法进行注销');
                            }else{
                                cancelCustomerNew({customerId:id}).then(
                                    response2=>{
                                        if(response2.data.data){
                                            this.table.refreshTable();
                                            return message.info('注销成功');
                                        }else{
                                            return message.info('注销失败');
                                        }
                                    })
                            }
                        });
                }

            }
        );
    };

    setTableValue = (value,text,rowData) => {
        switch (value) {
            case 'id':
                // 安装点参数
                var formsTwo_data={
                    customerId:     rowData?.id,			// 用户id
                    address:        rowData?.detailAddress,	// 地址
                    customerName:   rowData?.name,   		// 客户名称
                    createDate:'-',     // 创建时间
                    mpName:         rowData?.principal,		// 联系人
                    principalPhone: rowData?.principalPhone	// 电话
                };
                return (
                    <NavLink to={{pathname: "/customer/BusinessMaintainNew",state: {
                            id: rowData.id,
                            customerId: rowData.customerId??rowData.id,
                            type: '居民用户',
                            nature: rowData.nature,
                            address: rowData.detailAddress,
                            customerName: rowData.customerName,
                            principalPhone: rowData.principalPhone,
                            rowData:formsTwo_data
                        }}}>
                        <div className={'table_overflow'} title={text}>
                            <u>{text}</u>
                        </div>
                    </NavLink>
                );
            case 'type':
                text = text*1;
                return text*1 === 1 ? <Tag color={'geekblue'}>居民用户</Tag> : text*1 === 2 ? <Tag color={'green'}>工商用户</Tag> : '';
            case 'state':
                text = text*1;
                return text === 1 ? '正式' : text === 2 ? '非正式' : '';
            case 'edit':
                var formsTwo_data={
                    customerId:     rowData?.customerId,			// 用户id
                    address:        rowData?.address,	// 地址
                    customerName:   rowData?.name,   		// 客户名称
                    createDate:'-',     // 创建时间
                    mpName:         rowData?.principal,		// 联系人
                    principalPhone: rowData?.principalPhone	// 电话
                };
                return (
                    <div>

                        <div style={{paddingLeft:'10px',float: 'left'}}>
                            <Popconfirm title="是否注销客户?"
                                        onConfirm={()=>{this.cancelCustomer(rowData?.id)}}
                                // onCancel={cancel}
                                        okText="是" cancelText="否">
                                <a>注销客户</a>
                            </Popconfirm>
                        </div>
                        <div style={{paddingLeft:'10px',float: 'left'}}>
                            <Popconfirm title="是否打开?"
                                        onConfirm={()=>{
                                            rowData.id=rowData.customerId;
                                            var state = {
                                                rowData:rowData
                                            };

                                            window.open(this.state.href+"/customerDataPanel"           // 本地
                                                +
                                                "?id="+rowData.id+
                                                "&customerId="+rowData.customerId+
                                                "&customerName="+rowData.customerName+
                                                "&principalPhone="+rowData.principalPhone+
                                                "&type="+rowData?.type
                                            );
                                            // urlObject.state='state';
                                            // this.props.history.push({
                                            //     pathname:"/customerDataPanel",
                                            //     state:{
                                            //         rowData:rowData
                                            //     }
                                            // })
                                        }}
                                // onCancel={cancel}
                                        okText="是" cancelText="否">
                                <a>数据大屏</a>
                            </Popconfirm>
                        </div>
                    </div>
                );
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };
    updateCustomer = () => {
        let rowData = this.table.getSelectChange();
        if(rowData.length < 1) return message.error('未选择数据');
        console.log(rowData);
        // var params={
        //     id: rowData[0].customerId,
        //     businessCooperationNum: '业务合作号',
        //     name: '客户名称',
        //     type: '客户类型',
        //     nature: '客户性质',
        //     principal: '联系人',
        //     principalPhone: '联系人电话',
        //     addressInfo: '详细地址',
        //     edit: '操作',
        // };

        var params=rowData[0];
        // params.name=rowData[0].customerName;
        // params.detailAddress=rowData[0].address;
        this.props.history.push({
            pathname: '/customer/residentAccount',
            state: {
                rowData:params,
                selectWidth: true
            }
        })
    };
    render() {
        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <TableComponents
                    id={'id'}
                    paramenter={this.state.tableParamenter}
                    setData={newGetPageCustomerName}
                    setTableValue={this.setTableValue}
                    ref={e => this.table = e}
                >
                    <Radio.Group defaultValue="addClient" buttonStyle="solid">
                        <NavLink
                            to={{
                                pathname:"/customer/residentAccount",
                                state:{
                                    selectWidth: true
                                }
                            }}
                        >
                            <Radio.Button value="addClient">新增客户</Radio.Button>
                        </NavLink>
                        <Radio.Button value="updateClient" onClick={this.updateCustomer}>修改客户</Radio.Button>
                    </Radio.Group>
                </TableComponents>

            </Layout>
        )
    }
}
