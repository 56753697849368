import ajax from './ajax'
//阿里云
import axios from "axios";
import ajaxToServer from "./ajaxToServer";

// 登录 gateway
// export const doLogin = data => ajax('/gateway/user/doLogin', data, 'POST');

// 通用登录接口
export const doLogin = data => ajax('/user/doLogin', data, 'POST');
// 通用登出接口
export const logout = data => ajax('/user/logout', data, 'POST');
// 用户登录登出记录查询
export const pageUserTraces = data => ajax('/user/pageUserTraces', data, 'POST');




//阿里云
// export const doLogin = data => axios.post('/hdstLogin/user/doLogin', data);
