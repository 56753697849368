import React, { Component } from 'react'
import {Button, Input, Layout} from "antd"
import { CloseOutlined } from '@ant-design/icons';



import './home.less'
import {getHomeInfoByOrgIds} from "../../services/admin";
import moment from "moment";
const {TextArea} = Input;

export default class HomeChatWindow extends Component {

    state = {
    	// 聊天记录  文本信息-客服对话 left center right
		titleInfo_1:[
			// {name:'客服',title:'滴答滴答滴答滴答滴答滴答哒哒哒哒哒哒哒哒哒的点点滴滴的点点滴滴哒哒哒哒哒哒多大滴答滴答滴答滴答滴答滴答',float:'left'},
			// {name:null,title:'异常问题',float:'center'},
			// {name:'离散',title:'sds',float:'right'},
			// {name:'离散',title:'sds',float:'right'},
		],

		// 当前聊天人信息
		myCosInfo:{
			onlineStatus:'离线',
			cosType:'客户',
			name:null,
		},
		youCosInfo:{
			onlineStatus:'离线',
			cosType:'客户',
			name:null,
		},
		userInfo:null,		// 登录用户信息
		textValue:null,		// 聊天输入框内容
		bosFunction:false,	// 对话窗开关
		warnFunction:false,	// 警告开关
		socket:null,		// 连接
    };

    async componentDidMount(){


    	// 获取登入用户信息
		const user = JSON.parse(sessionStorage.getItem('user'));
		this.setState({userInfo:user});

		this.getHomeInfoByOrgIds(user?.id??null); // 验证当前用户是否客服还是客户

		// this.openSocket();// 开启服务
    }

	// 验证当前用户是否客服还是客户
	getHomeInfoByOrgIds=(userId)=>{
    	if(!userId){return false;}

    	console.log(userId);
		getHomeInfoByOrgIds({userId:userId}).then(
			response => {
				var myCosInfo = this.state.myCosInfo;// 当前人员信息
				myCosInfo.name=this.state.userInfo.realName;
				if(response?.data?.data){
					console.log('客服');
					myCosInfo.cosType='客服';
					this.openSocket(0);// 开启客服
				}else{
					console.log('不是客服');
					myCosInfo.cosType='客户';
				}
				this.setState({myCosInfo:myCosInfo})
			}
		);
	};



    // 更改对话框开关
    setHomeChatWindow=()=>{
    	if(!this.state.bosFunction){
    		console.log('开启');
			this.setCosOpenSocket();
		}else{
			console.log('不开启');
		}

		this.setState({
			bosFunction:!this.state.bosFunction,
			warnFunction:false
		});
	};

    // 给用户开启服务
    setCosOpenSocket=()=>{
    	const myCosInfo = this.state.myCosInfo;
    	if(myCosInfo.cosType.indexOf('客户')!==-1){
			this.openSocket(1);// 开启客服
			this.immediateServer(this.state.titleInfo_1.length);
		}
	};


	// 对话框文本
	dialogBoxTitle=(titles)=>{
		if(!titles) return false;
		return (
			titles.map(
				one=>{
					if(one.float.indexOf('left')!==-1){
						return<>
							<div style={{width:'100%',marginTop:'5px'}}>
								<div style={{width:'100%',height:'100%',paddingLeft:'10px'}}>
									<span style={{color:'#858585'}}>{one?.name}</span>
								</div>
								<div style={{width:'90%',height:'100%',marginLeft:'10px'}}>
									<span style={{width:'auto',paddingLeft:'10px',backgroundColor:'#fff',paddingRight:'10px',borderRadius: '2px',fontSize:'16px'}}>
										{one?.title}
									</span>
								</div>
							</div>
						</>
					} else if(one.float.indexOf('right')!==-1){
						return<>
							<div style={{width:'100%',marginTop:'5px'}}>
								<div style={{width:'100%',height:'22px',paddingRight:'10px',textAlign: 'right'}}>
									<span style={{color:'#858585'}}>{one?.name}</span>
								</div>

								<div style={{width:'95%',height:'100%',marginLeft:'10px',textAlign:'right'}}>
									<span style={{width:'auto',backgroundColor:'#d9ffc6',paddingLeft:'10px',paddingRight:'10px',borderRadius: '2px',fontSize:'16px'}}>
										{one?.title}
									</span>
								</div>
							</div>
						</>
					}else if(one.float.indexOf('center')!==-1){
						return<>
							<div style={{width:'100%',marginTop:'5px'}}>
								<div style={{width:'100%',height:'22px',paddingRight:'10px',textAlign: 'center'}}>
									<span style={{color:'#ee474b',fontSize:'14px'}}>{one?.title}</span>
								</div>
							</div>
						</>
					}
				}
			)
		)
	};
	// 手动添加对话
	addTitle=()=>{
		if(!this.state?.textValue) return false;
		var titleInfo1 = this.state.titleInfo_1;
		titleInfo1.push({
			name:'李四',
			title:this.state?.textValue,
			float: 'right'
		});
		let element = document.getElementById("box");
		element.scrollIntoView();               // 元素的顶部将对齐到可滚动祖先的可见区域的顶部。
		element.scrollIntoView(false);      // 元素的底部将与可滚动祖先的可见区域的底部对齐。
		// #对于新添加的元素有时可能不能立即获取到，此时可以设置个定时器
		setTimeout(()=>{
			let div=document.getElementById('box');
			if(div){div.scrollIntoView(false);}
		},200);
		this.setState({titleInfo_1:titleInfo1,textValue:null})
	};


	// 开启服务
	openSocket=(num)=>{
		let _that = this;   // 异步需要的this
		let {socket,titleInfo_1,myCosInfo,youCosInfo} = this.state;
		if(typeof(WebSocket) == "undefined"){ console.log("您的浏览器不支持WebSocket");return false}
		// socket = new WebSocket('ws://192.168.1.23:8383/websocket/'+(num??0)+'/'+this.state.userInfo.id);
		socket = new WebSocket('ws://47.97.117.248:8383/websocket/'+(num??0)+'/'+this.state.userInfo.id);
		// socket = new WebSocket('ws://192.168.1.134:10000/websocket/'+(num??0)+'/'+this.state.userInfo.id);
		//打开事件
		socket.onopen = function () {
			console.log("websocket已打开");
			myCosInfo.onlineStatus='在线中'
		};

		//获得消息事件
		socket.onmessage = function (msg) {
			console.log("获得消息事件");
			console.log(msg);
			if(_that.state.myCosInfo.cosType.indexOf('客服')!==-1){
				if(!_that.state.bosFunction){_that.setState({warnFunction:true})}
			}


			var msgData = JSON.parse(msg.data);
			//判断用户状态
			if(msgData.state !== undefined && msgData.state !== "success"){socket.close();}else{console.log('运行中')}
			// 判断是否有该数据-处理数据
			if(msgData?.msg){
				var index = msgData?.msg.indexOf('：');		//获取聊天信息
				var index1 = msgData?.msg.indexOf('服务');	//获取是否接收对象
				var index2 = msgData?.msg.indexOf('离线');	//获取客户是否离线
				var index3 = msgData?.msg.indexOf('正在为');	//获取客户是否离线

				if(index!==-1){
					console.log('获取聊天信息');
					const beforeStr = msgData?.msg.substr(0, index);  // 截取前半部分没有相同的值
					const afterStr = msgData?.msg.substr(index+1);    // 获取后半部分不相同的值
					youCosInfo.name=beforeStr;
					titleInfo_1.push({name:beforeStr,title:afterStr,float:'left'});
				}else if(index1!==-1){
					console.log('获取对接人');
					var param={name:null,title:null,float:'center'};
					// 0视为接收客户
					if(index3===0){
						//客户
						youCosInfo.name=msgData?.msg.substr(index3+3, index1-3);
						youCosInfo.cosType='客户';
						youCosInfo.onlineStatus='连线中';
						param.name=msgData?.msg.substr(index3+3, index1-3);
						param.title=msgData?.msg.substr(index3+3, index1-3)+'连接中1';
					}else{
						// 客服
						youCosInfo.name=msgData?.msg.substr(0,index3);
						youCosInfo.cosType='客服';
						youCosInfo.onlineStatus='连线中';
						param.name=msgData?.msg.substr(0,index3);
						param.title=msgData?.msg.substr(0,index3)+'连接中2';
					}
					titleInfo_1.push(param);
				}else if(index2!==-1){
					console.log('获取客户是否离线');
					youCosInfo.name='暂无连线人';
					youCosInfo.cosType='客服';
					youCosInfo.onlineStatus='暂无连线';
					titleInfo_1.push({name:null,title:'当前断开连线1'+moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),float:'center'});
				}else {
					console.log('是否异常');
					youCosInfo.name='暂无连线人';
					youCosInfo.cosType='客服';
					youCosInfo.onlineStatus='暂无连线';
					titleInfo_1.push({name:null,title:msgData.msg+moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),float:'center'});
				}
			}
			_that.setState({titleInfo_1});
			// 聊天窗锚点 消息进入 开始处理前端触发逻辑
			let element = document.getElementById("box");
			if(element){
				element.scrollIntoView();               // 元素的顶部将对齐到可滚动祖先的可见区域的顶部。
				element.scrollIntoView(false);      // 元素的底部将与可滚动祖先的可见区域的底部对齐。
			}
		};
		//关闭事件
		socket.onclose = function () {
			console.log("websocket已关闭");
			socket.close();
		};
		//发生了错误事件
		socket.onerror = function () {
			console.log("发生了错误事件");
			myCosInfo.onlineStatus='离线中';
			youCosInfo.name='暂无连线人';
			youCosInfo.cosType='客服';
			youCosInfo.onlineStatus='暂无连线';
			titleInfo_1.push({name:null,title:'连接失败请刷新页面重新连接'+moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),float:'center'});
			console.log("websocket发生了错误");
		};
		this.setState({socket:socket,myCosInfo:myCosInfo,youCosInfo:youCosInfo,titleInfo_1:titleInfo_1})
	};

	// 即时
	immediateServer=(num)=>{


		setTimeout(()=>{
			var titleInfo = this.state.titleInfo_1;
			if(num>=titleInfo.length){
				this.state.socket.onclose();
			}else{
				this.immediateServer(titleInfo.length);
			}
		},10000)
	};

    render() {
    	// 连接服务，登录人员信息，当前聊天人信息
    	const {socket,userInfo,myCosInfo,youCosInfo} = this.state;
		return (
			<div>
				{/*客服按钮功能*/}
				<div id={'homeDialogButton'}>
					{/*按钮*/}
					<div className={'homeDialogButton_div_1'}>
						<div className={'homeDialogButton_div_img_1'} style={{display: this.state.warnFunction?null:'none',}}/>
						<div className={'homeDialogButton_div_img'} onClick={()=>{this.setHomeChatWindow();}}/>
					</div>
				</div>
				{/*对话框*/}
				<div id={'homeDialogBox'} style={{display: this.state.bosFunction?null:'none',}}>
					<div id={'draggable1'} style={{width:'100%',height:'100%'}}>
						{/*顶部*/}
						<div className={'homeDialogBox_top_div'}>
							<div className={'homeDialogBox_top_div_1'}>
								<span style={{color:'#fff'}}>{myCosInfo.cosType??'聊天窗'}</span>
							</div>
							<div className={'homeDialogBox_top_div_2'}>
								<div style={{width:'97%',height:'100%',float:'left'}}/>
								<div style={{width:'3%',height:'100%',float:'left'}}>
									<CloseOutlined style={{color:'#fff',  cursor:'pointer'}}
												   onClick={()=>{
													   if(myCosInfo.cosType.indexOf("客户")!==-1){
														   socket.onclose();
													   }

													   this.setState({
														   bosFunction:!this.state.bosFunction,
														   warnFunction:false
													   });
												   }}
									/>
								</div>
							</div>
						</div>
						{/*主体*/}
						<div className={'homeDialogBox_body_div'}>
							<div style={{width:'100%',height:'100%',backgroundColor:'#fff'}}>
								{/*左侧*/}
								<div className={'homeDialogBox_body_div_left'}>
									{/*人物信息面板-登录人*/}
									<div style={{width:'100%',height:'45%',padding:'10px'}}>
										<div style={{width:'100%',height:'180px',padding:'1px'}}>
											<div className={myCosInfo.cosType.indexOf('客服')!==-1?'homeDialogBox_body_div_left_img_1':'homeDialogBox_body_div_left_img_2'} />
										</div>
										<div style={{width:'100%',height:'10%',padding:'1px',textAlign: 'center'}}>
											<span>状态：</span><span>{myCosInfo.onlineStatus}</span>
										</div>
										<div style={{width:'100%',height:'10%',padding:'1px',textAlign: 'center'}}>
											<span>{myCosInfo?.name??'登录人员名'}</span>
										</div>
									</div>
									{/*横线*/}
									<div style={{width:'100%',height:'1px',backgroundColor:'#eeeeee'}} />
									{/*人物信息面板-对方*/}
									<div style={{width:'100%',height:'54%',padding:'10px'}}>
										<div style={{width:'100%',height:'180px',padding:'1px'}}>
											<div className={myCosInfo.cosType.indexOf('客服')!==-1?'homeDialogBox_body_div_left_img_2':'homeDialogBox_body_div_left_img_1'} />
										</div>
										<div style={{width:'100%',height:'10%',padding:'1px',textAlign: 'center'}}>
											<span>状态：</span><span>{youCosInfo.onlineStatus??'暂无连线'}</span>
										</div>
										<div style={{width:'100%',height:'10%',padding:'1px',textAlign: 'center'}}>
											<span>{youCosInfo.name??'暂无连线人'}</span>
										</div>
										{/*<div style={{width:'100%',height:'10%',padding:'1px',textAlign: 'center'}}>*/}
										{/*	<Button>断开链接</Button>*/}
										{/*</div>*/}
									</div>
								</div>
								{/*主体*/}
								<div className={'homeDialogBox_body_div_right'}>
									<div className={'homeDialogBox_body_div_right_top_div'}>
										<div style={{
											width:'100%',height:'100%',
											borderRadius: '5px',
											backgroundColor:'#f9f9f9',
											overflowY: 'auto',
											padding:'2px'
										}}>
											{this.dialogBoxTitle(this.state.titleInfo_1)}
											<div id={'box'} style={{width:'100%',height:'1px',}}/>
										</div>
									</div>
									{/*横线*/}
									<div style={{width:'100%',height:'1px',backgroundColor:'#eeeeee'}} />
									<div className={'homeDialogBox_body_div_right_bottom_div'}>
										<div style={{width:'100%',height:'75%',padding:'1%'}}>
											<TextArea
												value={this.state.textValue}
												onChange={(text)=>{this.setState({textValue:text?.target?.value})}}
												paddingBlock={3}
												style={{width:700, height: 100, resize: 'none',border:'none'}}
											/>
										</div>
										<div style={{width:'100%',height:'25%'}}>
											<div style={{marginLeft:'87%'}}>
												<Button type={'primary'}
														onClick={()=>{
															this.addTitle();
															//发送消息
															socket.send(this.state.textValue);
														}}
												>
													发送
												</Button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        )
    }
}
