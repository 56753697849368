import { Component } from 'react'
import './login.less'
import { UserOutlined,LockOutlined } from '@ant-design/icons';
import { Redirect } from "react-router-dom"
import { Layout,Button,Form,Input, message,Checkbox } from 'antd';
import {getCode,doLogin } from '../../services/auth'
import {getHomeInfoByOrgId} from "../../services/configuration";
import Pubsub from "pubsub-js";
import {getDateByUserId} from "../../services/admin";
import axios from "axios";
import {TYPE_NAME} from "../../components/Const";
import FormsTwo from "../../components/common/forms/FormsTwo";
class Login extends Component {

	state = {
		// 根据需要修改这里会间接修改后续对应的参数
		loginType:4,// 1-新奥，2-汉德史特云端，3-新奥(数据面板),4-测试196。目前主要使用2号，
		isChecked: false,

		phone:null,				// 电话
		verificationCode:null,	// 验证码
		errorInfo:"",			// 错误提示
		displayType_1:true,		// 登录页面/密码找回
		displayType_2:'3',		// 获取验证码-1/验证验证码-2/密码修改-3
		displayType_3:false,	// 修改密码提示
		loading_1:false,		// 短信验证-下一步

	};
	componentDidMount(): void {
		console.log(this.state.displayType_2.indexOf('3')===0?'none':null)
	}


	onFinish = value => {
		doLogin(value).then(
			response => {
				if(response.data?.data){
					// 记住用户名
					let {voMenuAuthories, voCustomerNames,voDataAuthories, poUsers} = response.data?.data;
					sessionStorage.setItem("user", JSON.stringify(poUsers));
					sessionStorage.setItem("menuAuthories",JSON.stringify(voMenuAuthories));
					sessionStorage.setItem("dataAuthories",JSON.stringify(voDataAuthories));
					sessionStorage.setItem("customerNames",JSON.stringify(voCustomerNames));
					sessionStorage.setItem("loginType",JSON.stringify(this.state.loginType));// 1-标准云端(测试服并用)，2-汉德史特云端，3-新奥

					// localStorage.setItem('menus', voDataAuthories?.[0] ?? null);
					// this.getDateByUserId(poUsers?.id,poUsers.orgName);
					//1新奥2汉德不进入跳转数据面板
					(this.state.loginType===1 || this.state.loginType===2 || this.state.loginType===4)
						?
						response.data.data.org.name==='长沙新奥热力'?
							this.props.history.replace('/meterMap')	// 判断登录公司是否正确，调整管道地图
							:
							this.getHomeInfoByOrgId(poUsers)
							// this.props.history.replace('/customer/ClientIndustry')	// 通常页面跳转

						: this.props.history.replace('/regionalInfo');	// 数据面板跳转

					// this.props.history.replace('/customer');
					message.success('登录成功')
				}else{
					message.error('登入错误:'+response.data?.responseInfo.description)
				}
			}
		).catch(
			e => message.error("服务器异常")
		)
	};
	getDateByUserId=(userId,orgName,url)=>{
		if(!userId){return false;}
		getDateByUserId({userId:userId}).then(
			response => {
				if (response?.data) {
					if(response?.data.length===1){
						localStorage.setItem('menus',JSON.stringify(response?.data[0].id ?? null));
						return this.props.history.push({
							pathname: url,
							state: {
								selectWidth: true,
							}
						})
					}
				}
				this.props.history.replace('/customer/ClientIndustry')
			}
		).catch(e=> this.props.history.replace('/customer/ClientIndustry'))
	};
	// 根据组织机构ID查询首页配置
	getHomeInfoByOrgId=(a)=>{
		var param={
			"organizationId": a.orgId,
			"page": 1,
			"size": 10,
		};
		getHomeInfoByOrgId(param).then(
			reponse=>{
				if(reponse?.data?.data){
					var data = reponse?.data?.data;
					if(data[0]?.url){
						this.getDateByUserId(a?.id,a.orgName,data[0]?.url);
						// return this.props.history.push({
						// 	pathname: data[0]?.url,
						// 	state: {
						// 		selectWidth: true,
						// 		// areaIds:keys
						// 	}
						// })
					}else{
						this.props.history.replace('/customer/ClientIndustry')
					}
				}else{
					this.props.history.replace('/customer/ClientIndustry')
				}

			}
		).catch(e=> this.props.history.replace('/customer/ClientIndustry'))
	};


	onFinishFailed = error => console.log('错误提交');

	saveUserName = e => this.setState({isChecked: e.target.checked});


	// 获取短信验证码
	getCode=async (value)=>{
		this.setState({loading_1:true,phone:value?.phone??null});
		var param ={
			"phone": value?.phone,
			"signName": "汉德史特", "templateCode": "SMS_251785657"
		};
		try{
		    await axios.post('http://192.168.1.134:8989/admin/adminApp/userManagement/getCode',param).then(
				response=>{
					if(response.data.data){
						this.setState({loading_1:false,displayType_2:'2'});
					}
				}
			);
		}catch (e) {
			this.setState({loading_1:false});
		    console.log('异常问题',e);
		}
	};
	// 验证验证码
	verificationCode=async (value)=>{
		var param ={
			"code": value.code,
			"phone": this.state.phone
		};
		try{
		    await axios.post('http://192.168.1.134:8989/admin/adminApp/userManagement/verificationCode',param).then(
				response=>{
					if(response.data.data){
						this.setState({loading_1:false,displayType_2:'3',verificationCode:value.code});
					}
				}
			);
		}catch (e) {
			this.setState({loading_1:false});
		    console.log('异常问题',e);
		}
	};
	// 修改密码
	updatePassword=async (value)=>{

		var param ={
			"password": value.newPassword2,
			"phone": this.state.phone,
			"verificationCode": this.state.verificationCode
		};

		console.log(param);
		this.setState({displayType_3:true,loading_1:true});
		try{
			await axios.post('http://192.168.1.134:8989/admin/admin/user/updatePassword',param).then(
				response=>{
					if(response?.data?.data){
						message.info('修改成功');
						this.setState({loading_1:false,displayType_2:'1',displayType_1:true});
					}else{

						this.setState({loading_1:false,displayType_3:true,errorInfo:response?.data?.responseInfo?.description});
					}
				}
			);
		}catch (e) {
			this.setState({loading_1:false});
			console.log('异常问题',e);
		}

	};

	render() {

		const {displayType_2,displayType_3} = this.state;



		const userName = sessionStorage.getItem('userName');
		const {isChecked} = this.state;
		// if(localStorage.getItem('user_key')) return <Redirect to='/assetsManage'/>
		if(userName) return <Redirect to='/customer'/>;
		return (
			<Layout className={this.state.loginType===2?'login-body_1' :this.state.loginType===4?'login-body_3' :'login-body'}>
				{/*登录表单*/}
				<div className={'login-main'} style={{display:this.state.displayType_1?null:'none'}}>
					<div className={'login-main-title'}>欢迎登录</div>
					<Form
						style={{margin: '0vh 2vw'}}
						name="basic"
						initialValues={{ remember: true }}
						width={'30vw'}
						onFinish={this.onFinish}
						labelCol={{span: 5}}
						size={'large'}
						onFinishFailed={this.onFinishFailed}
					>
						<Form.Item
							name="account"
							initialValue={isChecked ? userName : null}
							rules={[{ required: true, message: '未输入用户名' }]}
						>
							<Input prefix={<UserOutlined />} placeholder={'请输入用户名'}/>
						</Form.Item>

						<Form.Item
							name="password"
							rules={[{ required: true, message: '未输入密码' }]}
							style={{marginBottom: '1.5vh'}}
						>
							<Input.Password prefix={<LockOutlined />} placeholder={'请输入密码'}/>
						</Form.Item>
						<div style={{margin: '2.5vh 0', padding: '0 1vw', color: '#525252'}}>
							<div style={{float: 'left'}}>
								<Checkbox onChange={this.saveUserName} style={{color: '#525252'}}>记住用户名</Checkbox>
							</div>
							<div style={{float: 'right',cursor:'pointer'}}
								 onClick={()=>{
								 	this.setState({displayType_1:false,displayType_2:'1'})
								 }}
							>忘记密码?</div>
						</div>
						<Button key='submit' type="primary" htmlType="submit" style={{width: '100%',marginTop: '30px'}}>登录</Button>
					</Form>
					<div className={'login-main-bottom-text'}>没有账号  ?  请联系云平台管理员</div>
				</div>
{/*--------------忘记密码-----------------------------------------*/}
				<div className={'login-main'}  style={{display:this.state.displayType_1?'none':null}}>
					{/*获取手机码---------------------------------------------------*/}
					<div style={{display:displayType_2.indexOf('1')!==-1?null:'none'}}>
						<div className={'login-main-title'}>找回密码</div>
						<Form
							style={{margin: '0vh 2vw'}}
							name="basic"
							initialValues={{ remember: true }}
							width={'30vw'}
							onFinish={this.getCode}
							labelCol={{span: 5}}
							size={'large'}
							onFinishFailed={this.onFinishFailed}
						>
							<Form.Item name="phone"
									   initialValue={isChecked ? userName : null}
									   rules={ [{ required: true, pattern: /^[1]([3-9])[0-9]{9}$/g, message: `电话号码格式不正确` }]}
							>
								<Input prefix={<UserOutlined />} placeholder={'请输入用户电话'}/>
							</Form.Item>

							<Button loading={this.state.loading_1} type="primary" htmlType="submit" style={{width: '100%',marginTop: '10px'}}>
								下一步
							</Button>
							<Button type="link" htmlType="submit" style={{marginLeft:'40%',marginTop: '10px'}}
									onClick={()=>{
										this.setState({displayType_1:true})
									}}
							>返回</Button>
						</Form>
					</div>
					{/*/!*验证手机码-------------------------------------------------------------------------*!/*/}
					<div style={{display:displayType_2.indexOf('2')!==-1?null:'none'}}>
						<div className={'login-main-title'}>找回密码</div>
						<div className={'login-main-bottom-text_1'}>短信已通过手机号发送到{this.state.phone}</div>
						<Form
							style={{margin: '0vh 2vw'}}
							name="basic"
							initialValues={{ remember: true }}
							width={'30vw'}
							onFinish={this.verificationCode}
							labelCol={{span: 5}}
							size={'large'}
							onFinishFailed={this.onFinishFailed}
						>
							<Form.Item name="code"
									   initialValue={isChecked ? userName : null}
									   rules={[{ required: true, message: '请输入验证码' }]}
							>
								<Input placeholder={'请输入验证码'} variant="borderless" />
							</Form.Item>

							<Button loading={this.state.loading_1} type="primary" htmlType="submit" style={{width: '100%',marginTop: '10px'}}>
								下一步
							</Button>
							<Button type="link" htmlType="submit" style={{marginLeft:'40%',marginTop: '10px'}}
									onClick={()=>{

										this.setState({displayType_2:'1'});
									}}
							>返回</Button>
						</Form>
					</div>
					{/*密码修改---------------------------------------------------*/}
					<div style={{display:displayType_2.indexOf('3')!==-1?null:'none'}}>
						<div className={'login-main-title'}>密码修改</div>
						<div className={'login-main-bottom-text_1'} style={{display:displayType_3?null:'none'}}>{this.state.errorInfo}</div>
						<Form
							style={{margin: '0vh 2vw'}}
							name="basic"
							initialValues={{ remember: true }}
							width={'30vw'}
							onFinish={this.updatePassword}
							labelCol={{span: 5}}
							size={'large'}
							onFinishFailed={this.onFinishFailed}
						>
							<Form.Item
								name="newPassword"
								// label="新密码："
								rules={[
									{
										required: true,
										message: '请输入新密码!',
									},
									{
										pattern: /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,20}/,
										message: '请输入8-20位数的密码，其中包含大小写字母、数字和特殊符号四种'
									},
								]}
							>
								<Input.Password
									size="large"
									placeholder="请输入新密码"
									prefix={<LockOutlined style={{ color: '#ec5e59' }} />}
									// iconRender={iconRender}
								/>
							</Form.Item>
							<Form.Item
								name="newPassword2"
								// label="复输新密码："
								rules={[
									{required: true, message: '请再次输入新密码!',},
									{
										pattern: /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,20}/,
										message: '请输入8-20位数的密码，其中包含大小写字母、数字和特殊符号四种'
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (
												!value ||
												(getFieldValue('newPassword') && getFieldValue('newPassword').trim()) === (value && value.trim())
											) {
												return Promise.resolve()
											}
											return Promise.reject(new Error('两次新密码请保持一致！'))
										},
									}),
								]}
							>
								<Input.Password
									size="large"
									placeholder="请再次输入新密码"
									prefix={<LockOutlined style={{ color: '#ec5e59' }} />}
									// iconRender={iconRender}

								/>
							</Form.Item>
							<Button loading={this.state.loading_1}
									type="primary" htmlType="submit"
									style={{width: '100%',marginTop: '10px'}}
							>
								下一步
							</Button>
							<Button type="link" htmlType="submit" style={{marginLeft:'40%',marginTop: '10px'}}
									onClick={()=>{this.setState({displayType_2:'1'});}}
							>返回</Button>
						</Form>
					</div>




					<div className={'login-main-bottom-text'}>没有账号  ?  请联系云平台管理员</div>
				</div>
				<div style={{width:'100%',height:'3%',position: 'absolute',marginTop: '97vh',textAlign: 'right',paddingRight:'20px'}}>
					<span style={{color: '#fff',cursor: 'pointer'}}
						  onClick={()=>{
						  	window.open('https://beian.miit.gov.cn/#/Integrated/index');
						  }}
					>湖南汉德史特仪表有限公司版权所有备案号：湘ICP备2021021424号-1</span>
				</div>
			</Layout>
		)
	}
}
export default Login
