import React, { Component } from 'react'
import {Layout, message, Tag, } from 'antd'
import {TYPE_NAME} from '../../components/Const'
import TableComponents from '../../components/common/tables/TableComponents'
import {pageUserTraces} from '../../services/auth'

// 用户登入登出日志管理
export default class userTracesPage extends Component {

    state = {
        // 表格参数
        tableParamenter: {
            manageName: "用户登入登出日志管理",
            // 是否绑定左侧区域
            bindArea: true,
            // 表格行是否可选
            rowSelection: true,
            // // 获取表格参数数据
            localStorageName: 'userTracesPage',
            //下拉框长度
            selectWidth: '7.5vw',
            // 表头：变量和变量解释
            fileName: '用户登入登出日志管理',
            header: {
                // userId:'用户Id',
                userName:'用户名',
                account:'账号',
                phone:'手机号',
                realName:'真实姓名',
                email:'邮箱',
                status:'操作状态',
                remark:'描述',
            },
            // 条件选择器参数
            conditionsParamenter: {
                // 条件
                parameter: [
                    {
                        type: TYPE_NAME.NAME,
                        variableName: 'userName',
                        str: '用户名称',
                        initData: JSON.parse(localStorage.getItem('userTracesPage'))?.name
                    },
                    {
                        type: TYPE_NAME.NAME,
                        variableName: 'account',
                        str: '账号',
                        initData: JSON.parse(localStorage.getItem('userTracesPage'))?.name
                    },
                ],
            },
            params:{
                page:1,
                size:10
            }
        },

        href:"http://localhost:3000"
    };
    componentWillMount() {}

    setTableValue = (value,text,rowData) => {
        switch (value) {
            case 'status':
                return text ? '成功' : '失败';
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };

    render() {
        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <TableComponents
                    id={'customerId'}
                    paramenter={this.state.tableParamenter}
                    setData={pageUserTraces}
                    setTableValue={this.setTableValue}
                    ref={e => this.table = e}
                />
            </Layout>
        )
    }
}
