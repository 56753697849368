import axios from "axios";

const service = axios.create({
    timeout: 1000 * 1000
});

// 不经过网关
export default function ajaxToServer(url, data={}, type='GET'){
    if(type === 'GET') return service.get(url, {params: data});
    else if(type === 'POST') return service.post(url, data);
    else if(type === 'PUT') return service.put(url, data);
    else if(type === 'DELETE') return service.delete(url, data)
}
